import React, {Fragment} from 'react';

// Material components
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Components
import CustomButton from '../Button';
import FormError from '../FormError';

// Images
import coin from '../../images/svg/coin.svg';

// Functions
import {getLanguageDescriptionByCode} from '../../core/common-functions';

const PollInfo = ({
	poll,
	selectedLang,
	setSelectedLang,
	formError,
	setFormError,
	isButtonLoading,
	startPoll,
	lang,
	text,
}) => {
	const {
		poll_name: name,
		poll_description: description,
		poll_langs: langs,
		status,
		price,
	} = poll;
	return (
		<Fragment>
			<div className="mb-2em">
				<h3>{name[lang] ? name[lang] : name[Object.keys(name)[0]]}</h3>
				<div className="label">{text.poll_description_header}:</div>
				<p>
					{description[lang]
						? description[lang]
						: description[Object.keys(description)[0]]}
				</p>
				<div className="label">{text.poll_description_count}:</div>
				<p>
					{parseInt(price) === 0 && (
						<span className="free">
							{text.poll_description_free}
						</span>
					)}
					{price > 0 && (
						<Fragment>
							до
							{' ' + price}
							<img
								src={coin}
								alt="Lemur Coin"
								width="16"
								className="coin"
							/>
						</Fragment>
					)}
				</p>
				{langs.length > 1 && status === 1 && (
					<div className="select-language">
						<div className="label">{text.select_lang}:</div>
						<ToggleButtonGroup
							size="medium"
							value={selectedLang}
							exclusive
							onChange={(e, value) => {
								setFormError('');
								setSelectedLang(value);
							}}>
							{langs.map((value, index) => {
								return (
									<ToggleButton value={value} key={index}>
										{getLanguageDescriptionByCode(value)}
									</ToggleButton>
								);
							})}
						</ToggleButtonGroup>
					</div>
				)}
				<FormError text={formError} className="mt-1em" />
			</div>
			<CustomButton
				text={
					(status === 1 ? text.start_poll : text.resume_poll) +
					' ' +
					text.poll
				}
				classNames={
					'btn-full ' +
					(status === 1 ? 'btn-colored-second' : 'btn-white')
				}
				isLoading={isButtonLoading}
				isDisabled={isButtonLoading}
				onClick={startPoll}
			/>
		</Fragment>
	);
};

export default PollInfo;
