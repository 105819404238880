import React from 'react';

// Images
import logo from '../../images/svg/logo-vertical.svg';

const PollError = ({text}) => {
	return (
		<div className="poll-empty">
			<img src={logo} alt="Lemur" width="100" />
			<h3>{text}</h3>
		</div>
	);
};

export default PollError;
