import React, {useEffect, useState, useCallback} from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';

import WithMainService from '../hoc/WithMainService';

// Redux
import {useSelector, useDispatch} from 'react-redux';
import {setBalance, setPathname} from '../../store/actions/app';

// Material Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Components
import EnrollmentHistoryDialog from '../EnrollmentHistoryDialog';
import WithdrawHistoryDialog from '../WithdrawHistoryDialog';
import AvailablePolls from '../AvailablePolls';

// Images
import coin from '../../images/svg/coin.svg';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const HomePage = ({mainService, signOut}) => {
	// Redux
	const {
		user: {firstName, lastName},
		balance,
		needInfo,
		lang,
		pathname,
	} = useSelector((state) => state.app);
	const dispatch = useDispatch();

	const history = useHistory();

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	const [polls, setPolls] = useState([]);

	const [withdrawHistoryOpen, setWithdrawHistoryOpen] = useState(false);
	const [withdrawHistoryLoading, setWithdrawHistoryLoading] = useState(true);
	const [withdrawHistory, setWithdrawHistory] = useState(null);

	const [enrollmentHistoryOpen, setEnrollmentHistoryOpen] = useState(false);
	const [enrollmentHistoryLoading, setEnrollmentHistoryLoading] = useState(
		true,
	);
	const [enrollmentHistory, setEnrollmentHistory] = useState(null);

	const [loadAvailablePolls, setLoadAvailablePolls] = useState(true);

	const getAvailablePolls = useCallback(() => {
		mainService
			.getAvailablePolls()
			.then((data) => {
				setPolls(data.available_polls);
				setLoadAvailablePolls(false);
			})
			.catch((error) => {
			});
	}, [mainService]);

	useEffect(() => {
		if (!needInfo) {
			// Если переход был по ссылке - делаем редирект на страницу опроса
			if (pathname && pathname.substr(1, 4) === 'poll') {
				dispatch(setPathname(null));
				history.push(pathname);
			} else {
				getAvailablePolls();
			}
		}
	}, [needInfo, getAvailablePolls, history, pathname, dispatch]);

	const showEnrollmentHistory = () => {
		setEnrollmentHistoryOpen(true);
		mainService
			.getEnrollmentHistory()
			.then((data) => {
				if (data.error) {
					// Произошла какая-то ошибка, не получилось данные получить - разлогин
					alert(data.error);
				} else {
					setEnrollmentHistory(data.enrollment_history);
					dispatch(setBalance(data.balance));
				}
				setEnrollmentHistoryLoading(false);
			})
			.catch((error) => {
			});
	};

	const showWithdrawHistory = () => {
		setWithdrawHistoryLoading(true);
		setWithdrawHistoryOpen(true);
		mainService
			.getWithdrawHistory()
			.then((data) => {
				if (data.error) {
					// Произошла какая-то ошибка, не получилось данные получить - разлогин
					alert(data.error);
				} else {
					setWithdrawHistory(data.withdraw_history);
					dispatch(setBalance(data.balance));
				}
				setWithdrawHistoryLoading(false);
			})
			.catch((error) => {
			});
	};

	if (needInfo) {
		return <Redirect to="/user-info" />;
	} else {
		return (
			<div className="container">
				<div className="user-panel">
					<div
						className={
							'poll-list' +
							(polls.length <= 0 ? ' poll-list--empty' : '')
						}>
						<h5>{TEXT.available_polls_header}</h5>
						<AvailablePolls
							polls={polls}
							loadAvailablePolls={loadAvailablePolls}
							texts={{
								empty: TEXT.empty_polls,
								start: TEXT.start_poll,
								resume: TEXT.resume_poll,
								poll: TEXT.poll,
							}}
							lang={lang}
						/>
					</div>
					<div className="balance">
						<div className="balance__inner">
							<h3 className="text-center">
								{firstName} {lastName}
							</h3>
							<h4>
								{TEXT.panel_page_balance_text}
								<br />
								<div className="balance-count">
									<span className="size-2 c-main">
										{balance}
									</span>
									<img src={coin} alt="Лемур" width="16" />
								</div>
							</h4>
							<Link
								className="btn btn-colored btn-md btn-full btn-color-shadow"
								to="/withdraw">
								{TEXT.withdraw}
							</Link>
							<div className="balance-info">
								<div className="coin-count">
									<img src={coin} alt="Лемур" width="12" />
									{' - '}
									внутрішня валюта сервісу
								</div>
								<br />
								<div className="coin-count">
									<span>10</span>
									<img src={coin} alt="Лемур" width="12" />
								</div>{' '}
								= 1 {TEXT.uah}
							</div>
						</div>
						<div className="other-actions">
							<List component="nav">
								<ListItem
									button
									onClick={showEnrollmentHistory}>
									<ListItemText>
										{TEXT.enroll_history}
									</ListItemText>
								</ListItem>
								<ListItem button onClick={showWithdrawHistory}>
									<ListItemText
										primary={TEXT.withdraw_history}
									/>
								</ListItem>
								<ListItem button onClick={signOut}>
									<ListItemText primary={TEXT.sign_out} />
								</ListItem>
							</List>
						</div>
					</div>
				</div>
				<EnrollmentHistoryDialog
					loading={enrollmentHistoryLoading}
					open={enrollmentHistoryOpen}
					setOpen={setEnrollmentHistoryOpen}
					data={enrollmentHistory}
					lang={lang}
					titleText={TEXT.enroll_history}
					closeText={TEXT.close}
					archiveText={TEXT.archive_poll}
				/>
				<WithdrawHistoryDialog
					loading={withdrawHistoryLoading}
					open={withdrawHistoryOpen}
					setOpen={setWithdrawHistoryOpen}
					data={withdrawHistory}
					titleText={TEXT.withdraw_history}
					closeText={TEXT.close}
				/>
			</div>
		);
	}
};

export default WithMainService()(HomePage);
