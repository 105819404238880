import React from 'react';

// Components
import EnrollmentHistoryItem from './EnrollmentHistoryItem';

const EnrollmentHistory = ({data, lang}) => {
	const dataLength = Object.keys(data).length;
	if (dataLength > 0) {
		return (
			<div className="modal-data-list">
				{Object.keys(data).map((key, index) => {
					const value = data[key];
					const date = new Date(key);
					return (
						<EnrollmentHistoryItem
							index={index}
							value={value}
							date={date}
							lang={lang}
							key={index}
						/>
					);
				})}
			</div>
		);
	} else {
		return <div className="modal-data-list--empty">Пусто</div>;
	}
};

export default EnrollmentHistory;
