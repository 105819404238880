import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import WithMainService from '../hoc/WithMainService';

// Material components
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

// Components
import Button from '../Button';
import SocialAuth from '../SocialAuth';
import AuthAnotherActionBlock from '../AuthAnotherActionBlock';
import FormError from '../FormError';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {
	setAuthToken,
	setUser,
	setBalance,
	setNeedInfo,
	setLang,
} from '../../store/actions/app';

// Functions
import {validateEmail, setHTMLDocumentLang} from '../../core/common-functions';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const SignInPage = ({mainService}) => {
	const [email, setEmail] = useState(''); //a.s.papko1@gmail.com
	const [emailError, setEmailError] = useState(null);
	const [password, setPassword] = useState(''); //014547
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [snack, setSnack] = useState({
		isOpen: false,
		text: '',
	});

	// Redux
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnack({
			isOpen: false,
			text: '',
		});
	};

	const signIn = (type, token, data) => {
		setIsLoading(true);
		let userData = {
			type,
		};

		if (type === 'email') {
			userData = {
				...userData,
				email: data.email,
				password: data.password,
			};
		} else {
			userData.social_token = token;
		}

		return new Promise((resolve, reject) => {
			mainService
				.signIn(userData)
				.then((data) => {
					if (data.error) {
						setIsLoading(false);
						reject(data);
					} else {
						dispatch(setNeedInfo(data.need_info));
						dispatch(setBalance(data.balance));
						if (data.user.LANG) {
							dispatch(setLang(data.user.LANG));
							setHTMLDocumentLang(data.user.LANG);
						}
						dispatch(
							setUser({
								firstName: data.user.F_NAME,
								id: data.user.ID,
								lastName: data.user.L_NAME,
								phone: data.user.MOB_PHONE,
							}),
						);
						localStorage.setItem('authToken', data.token);
						dispatch(setAuthToken(data.token));
					}
				})
				.catch((err) => {
					reject(err);
					setIsLoading(false);
					setSnack({
						isOpen: true,
						text: ERR_TEXT.something_went_wrong,
					});
				});
		});
	};

	const onEmailSignIn = (e) => {
		e.preventDefault();
		setFormError(null);
		if (!validateEmail(email)) {
			setEmailError(ERR_TEXT[1001]);
			return;
		}
		setIsButtonLoading(true);
		signIn('email', null, {email, password}).catch((error) => {
			if (error.code) {
				setFormError(ERR_TEXT[error.code]);
			}
			setIsButtonLoading(false);
		});
	};

	return (
		<div className="container">
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={snack.isOpen}
				onClose={handleSnackClose}
				message={snack.text}
				TransitionComponent={Slide}
				autoHideDuration={3000}
			/>
			<h2 className="text-center">{TEXT.sign_in_page_header}</h2>
			<div className="auth-pages">
				<div className="auth-form">
					<form
						className="m-auto max-width-900"
						onSubmit={onEmailSignIn}>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(emailError)}
								label="Email"
								helperText={emailError}
								type="email"
								required
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									setEmailError(null);
								}}
								autoComplete="user-name"
							/>
						</div>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								label={TEXT.input_pass_text}
								type="password"
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								autoComplete="current-password"
							/>
						</div>
						<Link className="forgot-pass" to="/reset-password">
							{TEXT.forgot_pass}
						</Link>
						<div className="text-center">
							<Button
								text={TEXT.sign_in}
								classNames="btn-colored"
								isLoading={isButtonLoading}
								isDisabled={isButtonLoading || isLoading}
							/>
						</div>
						<FormError text={formError} className="mt-2em" />
					</form>
					<SocialAuth
						event={signIn}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						setFormError={setFormError}
						errText={ERR_TEXT}
					/>
				</div>
				<AuthAnotherActionBlock isLogin />
			</div>
		</div>
	);
};

export default WithMainService()(SignInPage);
