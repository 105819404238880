import React from 'react';

const SpinnerItems = () => {
	let spinnerItems = [];
	for (let i = 0; i < 12; i++) {
		spinnerItems.push(<i key={i}></i>);
	}
	return spinnerItems;
};

const Spinner = () => {
	return (
		<div className="spinner">
			<SpinnerItems />
		</div>
	);
};

export default Spinner;
