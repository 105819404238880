import React, {useEffect} from 'react';

// Redux
import {useSelector} from 'react-redux';

// Components
import {IntroBlock, AboutBlock, AppBlock} from '../StartPage';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const StartPage = () => {
	// Redux
	const authToken = useSelector((state) => state.app.authToken);
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	useEffect(() => {
		const list = document.querySelectorAll('svg');
		for (const element of list) {
			element.classList.add('prefix__animated');
		}
	}, []);

	return (
		<div className="container">
			<IntroBlock text={TEXT} isLogin={authToken} />
			<AboutBlock text={TEXT} isLogin={authToken} />
			<AppBlock text={TEXT} />
		</div>
	);
};

export default StartPage;
