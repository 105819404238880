import React, {useState} from 'react';

// Redux
import {useSelector, useDispatch} from 'react-redux';
import {setBalance} from '../../store/actions/app';

// HOC
import WithMainService from '../hoc/WithMainService';

// Material components
import {TextField, Select, InputLabel, FormControl} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

// Components
import Button from '../Button';
import TextMaskCustom from '../TextMaskCustom';
import FormError from '../FormError';

// Images
import coin from '../../images/svg/coin.svg';
import tabletochki from '../../images/svg/tabletochki.svg';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const WithdrawPage = ({mainService}) => {
	// Redux
	const dispatch = useDispatch();
	const balance = useSelector((state) => state.app.balance);
	const lang = useSelector((state) => state.app.lang);

	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [withdrawType, setWithdrawType] = useState('1');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [count, setCount] = useState('');
	const [countError, setCountError] = useState('');
	const [formSuccess, setFormSuccess] = useState(null);
	const [formError, setFormError] = useState(false);
	const [minWithdraw, setMinWithdraw] = useState(500);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const formSubmit = (e) => {
		e.preventDefault();
		setPhoneError('');
		setCountError('');
		setFormSuccess('');
		setFormError('');
		const replacedPhone = phone.replace(/[^.\d]+/g, '');
		const countInt = +count;
		if (replacedPhone.length !== 12) {
			setPhoneError(ERR_TEXT.incorrect_phone);
		}

		if (countInt < minWithdraw) {
			setCountError(ERR_TEXT.min_count + ' ' + minWithdraw);
		}

		if (countInt > balance) {
			setCountError(ERR_TEXT.more_than_balance);
		}

		if (countInt >= minWithdraw && countInt <= balance) {
			let data = {
				amount: count,
				type: withdrawType,
			};
			if (withdrawType === '1') {
				if (replacedPhone.length === 12) {
					data.phone = replacedPhone;
				} else {
					return false;
				}
			}
			setIsLoading(true);
			setIsButtonLoading(true);
			mainService
				.withdraw(data)
				.then((response) => {
					if (response.success === 1) {
						dispatch(setBalance(response.balance));
						setPhone('');
						setCount('');
						setFormSuccess(TEXT.withdraw_success);
					} else {
						if (response.error) {
							setFormError(ERR_TEXT[response.code]);
						}
					}
					setIsLoading(false);
					setIsButtonLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					setIsButtonLoading(false);
				});
		}
	};

	return (
		<div className="container">
			<div className="withdraw-container">
				<div className="withdraw">
					<div className="balance">
						Ваш баланс: <strong>{balance}</strong>{' '}
						<img src={coin} alt="Лемур" width="18" />
					</div>
					<form onSubmit={formSubmit}>
						<div className="field">
							<FormControl fullWidth>
								<InputLabel htmlFor="select-region">
									{TEXT.withdraw_type}
								</InputLabel>
								<Select
									required
									fullWidth
									native
									value={withdrawType}
									onChange={(e) => {
										setWithdrawType(e.target.value);
										if (e.target.value === '1') {
											setMinWithdraw(500);
										} else {
											setMinWithdraw(100);
										}
									}}
									inputProps={{
										name: 'region',
										id: 'select-region',
									}}
									disabled={isLoading}>
									<option value="1">Телефон</option>
									<option value="2">{TEXT.charity}</option>
								</Select>
							</FormControl>
						</div>
						{withdrawType === '1' && (
							<div className="field">
								<TextField
									fullWidth
									label={TEXT.phone_number}
									required
									value={phone}
									onChange={(e) => {
										setPhone(e.target.value);
										setPhoneError('');
									}}
									disabled={isLoading}
									InputProps={{
										inputComponent: TextMaskCustom,
									}}
									InputLabelProps={{
										shrink: Boolean(phone),
									}}
									error={Boolean(phoneError)}
									helperText={phoneError}
								/>
							</div>
						)}
						<div className="field">
							<TextField
								error={Boolean(countError)}
								helperText={countError}
								fullWidth
								label={TEXT.coin_count}
								required
								type="number"
								value={count}
								onChange={(e) => setCount(e.target.value)}
								disabled={isLoading}
							/>
						</div>
						{withdrawType === '2' && (
							<div className="tabletochki">
								<div className="image">
									<img src={tabletochki} alt="Tabletochki" />
								</div>
								<p
									dangerouslySetInnerHTML={{
										__html: TEXT.tabletochki_withdraw,
									}}></p>
							</div>
						)}
						{withdrawType === '1' && (
							<div className="tabletochki">
								<p>{TEXT.withdraw_info_phone}</p>
							</div>
						)}
						<FormError text={formError} className="mb-2em" />
						<Collapse in={Boolean(formSuccess)}>
							<Alert severity="success" className="mb-2em">
								{formSuccess}
							</Alert>
						</Collapse>
						<Button
							text={TEXT.confirm}
							classNames="btn-colored-second btn-full"
							isLoading={isButtonLoading}
							isDisabled={isButtonLoading || isLoading}
						/>
					</form>
				</div>
				<div className="balance-info">
					<div className="coin-count">
						<span>10</span>
						<img src={coin} alt="Лемур" width="12" />
					</div>{' '}
					= 1 {TEXT.uah}
					<br />
					{TEXT.withdraw_min}{' '}
					<div className="coin-count">
						<span>{minWithdraw}</span>
						<img src={coin} alt="Лемур" width="12" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithMainService()(WithdrawPage);
