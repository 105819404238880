const validateEmail = (email) => {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

const addOpacityToColor = (color, opacity) => {
	let result;
	if (color.indexOf('a') === -1) {
		result = color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
	} else {
		throw new Error('Color format must be rgb(x, x, x).');
	}
	return result;
};

const getLanguageDescriptionByCode = (code) => {
	const languages = {
		ru: 'Русский',
		ua: 'Українська',
		en: 'English',
	};
	if (languages.hasOwnProperty(code)) {
		return languages[code];
	} else {
		throw new Error('Code not match');
	}
};

const setHTMLDocumentLang = (lang) => {
	if(lang === 'ua') {
		lang = 'uk';
	}
	document.documentElement.lang = lang;
};

// Преобразование телефона в формате 380999999999 в +380 (099) 99 99 999
const formatPhone = (phone) => {
	return `+${phone.substr(0, 2)} (${phone.substr(2, 3)}) ${phone.substr(
		5,
		2,
	)} ${phone.substr(7, 2)} ${phone.substr(9, 3)}`;
};

const generateIndexList = (range) => {
	let list = [];
	for (let i = range.from; i <= range.to; i++) {
		list.push({
			value: i.toString(),
			label: i.toString(),
		});
	}
	return list;
};

export {
	validateEmail,
	addOpacityToColor,
	getLanguageDescriptionByCode,
	formatPhone,
	generateIndexList,
	setHTMLDocumentLang,
};
