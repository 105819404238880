import {
	SET_AUTH_TOKEN,
	SET_USER,
	SET_BALANCE,
	SET_NEED_INFO,
	SET_LANG,
	SET_PATHNAME,
} from '../types';

const setAuthToken = (token) => {
	return {
		type: SET_AUTH_TOKEN,
		payload: token,
	};
};

const setUser = (user) => {
	return {
		type: SET_USER,
		payload: user,
	};
};

const setBalance = (balance) => {
	return {
		type: SET_BALANCE,
		payload: balance,
	};
};

const setNeedInfo = (needInfo) => {
	return {
		type: SET_NEED_INFO,
		payload: needInfo,
	};
};

const setLang = (lang) => {
	return {
		type: SET_LANG,
		payload: lang,
	};
};

const setPathname = (url) => {
	return {
		type: SET_PATHNAME,
		payload: url,
	};
};

export {setAuthToken, setUser, setBalance, setNeedInfo, setLang, setPathname};
