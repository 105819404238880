import React from 'react';

// Components
import Spinner from '../Spinner';

const PollLoading = ({text}) => {
	return (
		<div className="spinner-container">
			<Spinner />
			<p>{text}..</p>
		</div>
	);
};

export default PollLoading;
