import React from 'react';
import {Link} from 'react-router-dom';

// Images
import Image from '../../images/svg/intro-2';

const AboutBlock = ({text, isLogin}) => {
	return (
		<div className="about-block flex align-center">
			<div className="icon">
				<Image />
			</div>
			<div className="text pl-4em">
				<h2>{text.about_project}</h2>
				{text.start_page_block_2_p.map((item, index) => {
					return (
						<p
							key={index}
							dangerouslySetInnerHTML={{__html: item}}></p>
					);
				})}
				<div className="mt-4em">
					{!isLogin && (
						<Link
							className="btn btn-colored btn-md mr-2em"
							to="/sign-up">
							{text.sign_up}
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default AboutBlock;
