import {
	SET_AUTH_TOKEN,
	SET_USER,
	SET_BALANCE,
	SET_NEED_INFO,
	SET_LANG,
	SET_PATHNAME,
} from '../types';

const initialState = {
	authToken: null,
	user: {
		firstName: null,
		id: null,
		lastName: null,
		phone: null,
	},
	needInfo: null,
	balance: null,
	lang: 'ua',
	pathname: null,
};

export const appReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_AUTH_TOKEN:
			return {
				...state,
				authToken: payload,
			};
		case SET_USER:
			return {
				...state,
				user: payload,
			};
		case SET_BALANCE:
			return {
				...state,
				balance: payload,
			};
		case SET_NEED_INFO:
			return {
				...state,
				needInfo: payload,
			};
		case SET_LANG:
			return {
				...state,
				lang: payload,
			};
		case SET_PATHNAME:
			return {
				...state,
				pathname: payload,
			};
		default:
			return state;
	}
};
