import React from 'react';
import Spinner from '../Spinner';

const Button = ({
	text,
	classNames,
	isLoading,
	isDisabled,
	buttonType = 'submit',
	onClick,
}) => {
	let btnClasses = `btn ${classNames}`;
	if (isLoading) {
		btnClasses += ' loading';
	}
	if (isDisabled) {
		btnClasses += ' disabled';
	}
	return (
		<button
			className={btnClasses}
			type={buttonType}
			disabled={isDisabled}
			onClick={onClick}>
			{text}
			<Spinner />
		</button>
	);
};

export default Button;
