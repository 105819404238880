import React from 'react';

// Redux
import {useSelector} from 'react-redux';

// Images
import justLogo from '../../images/svg/just-logo.svg';

// JSON
import ERROR_TEXTS from '../../core/json/errors.json';

const NoMatchPage = () => {
	// Redux
	const lang = useSelector((state) => state.app.lang);

	const TEXT = ERROR_TEXTS[lang];

	return (
		<div className="error-page">
			<div className="error-page--code">
				4
				<span>
					<img src={justLogo} alt="Lemur" />
				</span>
				4
			</div>
			<div className="error-page--text">{TEXT[404]}</div>
		</div>
	);
};

export default NoMatchPage;
