let ADMIN_DOMAIN = 'https://dev.admin.lemur.ua/';
let DOMAIN = 'https://dev.lemur.ua/';

if (
	process.env.NODE_ENV === 'production' &&
	window.location.host.substr(0, 3) !== 'dev'
) {
	ADMIN_DOMAIN = 'https://admin.lemur.ua/';
	DOMAIN = 'https://lemur.ua/';
}

const API_BASE_URL = ADMIN_DOMAIN + 'api/app/panel/';

const FONTS = {
	regular: 'ProximaNova-Regular',
	bold: 'ProximaNova-Bold',
	extraBold: 'ProximaNova-Extrabld',
};

const STATUS_BAR_CONTENT_COLOR = {
	light: 'dark-content',
	dark: 'light-content',
};

const ACCENT_COLORS = {
	color: 'rgb(50, 124, 198)',
	color2: 'rgb(0, 0, 110)',
	color3: 'rgb(255, 171, 0)',
};

const THEME = {
	light: {
		bgColor: '#FFF',
		bgColorSecondary: '#EEE',
		textColor: '#000',
		textColorSecondary: '#8A8F9E',
	},
	dark: {
		bgColor: '#121212',
		bgColorSecondary: '#272727',
		textColor: '#FFFFFF',
		textColorSecondary: 'rgb(229, 229, 231)',
	},
};

const MONTH = [
	{
		ru: 'января',
		ua: 'cічня',
	},
	{
		ru: 'февраля',
		ua: 'лютого',
	},
	{
		ru: 'марта',
		ua: 'березня',
	},
	{
		ru: 'апреля',
		ua: 'квітня',
	},
	{
		ru: 'мая',
		ua: 'травня',
	},
	{
		ru: 'июня',
		ua: 'червня',
	},
	{
		ru: 'июля',
		ua: 'липня',
	},
	{
		ru: 'августа',
		ua: 'серпня',
	},
	{
		ru: 'сентября',
		ua: 'вересня',
	},
	{
		ru: 'октября',
		ua: 'жовтня',
	},
	{
		ru: 'ноября',
		ua: 'листопада',
	},
	{
		ru: 'декабря',
		ua: 'грудня',
	},
];

const EDUCATION_LEVELS = [
	{
		value: '1',
		label: {
			ru: 'Начальное, неполное среднее',
			ua: 'Початкова, неповна середня',
		},
	},
	{
		value: '2',
		label: {
			ru: 'Полное среднее',
			ua: 'Повна середня',
		},
	},
	{
		value: '3',
		label: {
			ru: 'Среднее специальное (техникум, училище, колледж)',
			ua: 'Середня спеціальна (технікум, училище, коледж)',
		},
	},
	{
		value: '4',
		label: {
			ru: 'Высшее (в том числе неполное высшее)',
			ua: 'Вища (в тому числі неповна вища)',
		},
	},
];

const MARITAL_STATUSES = [
	{
		value: '1',
		label: {
			ru: 'Не замужем / Не женат',
			ua: 'Не заміжня / Не одружений',
		},
	},
	{
		value: '2',
		label: {
			ru: 'Замужем / женат',
			ua: 'Заміжня / одружений',
		},
	},
	{
		value: '3',
		label: {
			ru: 'Живу в гражданском браке',
			ua: 'Живу в цивільному шлюбі',
		},
	},
	{
		value: '4',
		label: {
			ru: 'Разведен(а)',
			ua: 'Розлучений(а)',
		},
	},
	{
		value: '5',
		label: {
			ru: 'Вдовец / вдова',
			ua: 'Вдівець / вдова',
		},
	},
];

const LOCATION_TYPE = [
	{
		value: '1',
		label: {
			ru: 'Город',
			ua: 'Місто',
		},
	},
	{
		value: '2',
		label: {
			ru: 'Посёлок городского типа',
			ua: 'Селище міського типу',
		},
	},
	{
		value: '3',
		label: {
			ru: 'Село',
			ua: 'Село',
		},
	},
];

const STATUS_ICONS = [
	{icon: 'clock', color: '#ffc107'},
	{icon: 'check', color: 'green'},
	{icon: 'close-a', color: 'red'},
];

const HOW_KNOW = [
	{
		value: 1,
		label: {
			ru: 'Рассказали друзья',
			ua: 'Розповіли друзі',
		},
	},
	{
		value: 2,
		label: {
			ru: 'Пришло письмо с приглашением',
			ua: 'Прийшов лист із запрошенням',
		},
	},
	{
		value: 3,
		label: {
			ru: 'Реклама в СМИ',
			ua: 'Реклама в ЗМІ',
		},
	},
	{
		value: 4,
		label: {
			ru: 'Дали рекламную визитку',
			ua: 'Далі рекламну візитку',
		},
	},
	{
		value: 5,
		label: {
			ru: 'Социальные сети',
			ua: 'Соціальні мережі',
		},
	},
	{
		value: 6,
		label: {
			ru: 'Интернет',
			ua: 'Інтернет',
		},
	},
	{
		value: 7,
		label: {
			ru: 'Проходил опрос по ссылке',
			ua: 'Проходил опитування за посиланням',
		},
	},
];

const STYLES = {
	horizontalIndent: 16,
	blockTitleFontSize: 18,
	blockTitleFontFamily: FONTS.extraBold,
	blockTitleMarginBottom: 16,
	listHeaderFontSize: 20,
	listHeaderFontFamily: FONTS.bold,
	listItemFontSize: 16,
	listItemFontFamily: FONTS.regular,
};

export {
	MONTH,
	STATUS_ICONS,
	HOW_KNOW,
	THEME,
	API_BASE_URL,
	STATUS_BAR_CONTENT_COLOR,
	FONTS,
	ACCENT_COLORS,
	STYLES,
	DOMAIN,
	EDUCATION_LEVELS,
	MARITAL_STATUSES,
	LOCATION_TYPE,
};
