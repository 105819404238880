// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import {Redirect, Route} from 'react-router-dom';

// Redux
import {useSelector} from 'react-redux';

const PrivateRoute = ({component: Component, ...rest}) => {
	// Redux
	const {authToken} = useSelector((state) => state.app);

	return (
		<Route
			{...rest}
			render={(props) =>
				authToken ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{pathname: '/sign-in', state: {from: props.location}}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
