import React, {useState, useEffect, Fragment} from 'react';

import {useHistory} from 'react-router-dom';

// Material components
import {
	Radio,
	RadioGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
	TextField,
	Grid,
	FormHelperText,
	InputLabel,
	Select,
	FormGroup,
	Checkbox,
	Button,
} from '@material-ui/core';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setUser, setBalance, setNeedInfo} from '../../store/actions/app';

// Material picker
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';

// JSONs
import regions from '../../core/json/regions.json';
import towns from '../../core/json/towns.json';
import pgts from '../../core/json/pgts.json';
import indexes from '../../core/json/indexes.json';

// Components
import CustomButton from '../Button';
import TextMaskCustom from '../TextMaskCustom';

// HOC
import WithMainService from '../hoc/WithMainService';

// Constants
import {
	HOW_KNOW,
	DOMAIN,
	EDUCATION_LEVELS,
	MARITAL_STATUSES,
	LOCATION_TYPE,
} from '../../core/constants';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

// Functions
import {generateIndexList} from '../../core/common-functions';

const UserInfoPage = ({mainService, signOut}) => {
	// Обновление информации о пользователе
	const [isUpdate, setIsUpdate] = useState(false);
	const [gender, setGender] = useState('');
	const [genderError, setGenderError] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [birthDate, setBirthDate] = useState(null);
	const [birthDateError, setBirthDateError] = useState('');
	// Уровень образования
	const [educationLevel, setEducationLevel] = useState('');
	// Семейное положение
	const [maritalStatus, setMaritalStatus] = useState('');
	// Тип населённого пункта
	const [locationType, setLocationType] = useState('');
	// Название населённого пункта
	const [locationName, setLocationName] = useState('');
	const [region, setRegion] = useState('');
	const [town, setTown] = useState('');
	const [index, setIndex] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [hasChild, setHasChild] = useState('');
	const [hasChildError, setHasChildError] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [howKnow, setHowKnow] = useState('');
	const [agreement, setAgreement] = useState(false);

	// Redux
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const history = useHistory();

	useEffect(() => {
		mainService.getUserInfo().then((data) => {
			if (data.user_info) {
				const {user_info: userInfo} = data;
				let dataBirthDate = null;
				if (userInfo.B_YEAR && userInfo.B_MONTH && userInfo.B_DAY) {
					dataBirthDate = new Date(
						`${userInfo.B_YEAR}-${userInfo.B_MONTH}-${userInfo.B_DAY}`,
					);
				}
				if (userInfo.F_NAME) {
					setIsUpdate(true);
				}
				setGender(userInfo.GENDER || '');
				setFirstName(userInfo.F_NAME || '');
				setLastName(userInfo.L_NAME || '');
				setMiddleName(userInfo.M_NAME || '');
				setBirthDate(dataBirthDate);
				setRegion(userInfo.REGION_ID || '');
				setTown(userInfo.CITY_ID || '');
				setIndex(userInfo.INDEX_CITY || '');
				setPhone(userInfo.MOB_PHONE || '');
				setHasChild(userInfo.KIDS || '');
				setHowKnow(userInfo.HOW_KNOW || '');
				setLocationType(userInfo.LOCATION_TYPE || '');
				setEducationLevel(userInfo.EDUCATION_LEVEL || '');
				setMaritalStatus(userInfo.MARITAL_STATUS || '');
				setLocationName(userInfo.LOCATION_NAME || '');
			}
		});
	}, [mainService]);

	const formSubmit = (e) => {
		e.preventDefault();
		const replacedPhone = phone.replace(/[^.\d]+/g, '');
		if (!gender) {
			setGenderError(ERR_TEXT.choose_option);
		}
		if (!hasChild) {
			setHasChildError(ERR_TEXT.choose_option);
		}
		if (replacedPhone.length !== 12) {
			setPhoneError(ERR_TEXT.incorrect_phone);
		}
		if (!birthDate) {
			setBirthDateError(ERR_TEXT.set_date);
		}

		if (gender && hasChild && birthDate && replacedPhone.length === 12) {
			const date = new Date(birthDate);
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			setIsButtonLoading(true);
			setIsLoading(true);
			let userInfo = {
				gender,
				f_name: firstName,
				l_name: lastName,
				m_name: middleName,
				birth_day: day,
				birth_month: month,
				birth_year: year,
				state: region,
				mob_phone: replacedPhone,
				kids: hasChild,
				how_know: howKnow,
				location_type: locationType,
				education_level: educationLevel,
				marital_status: maritalStatus,
			};

			if (locationType === '3') {
				userInfo.location_name = locationName;
			} else {
				userInfo.city = town;
				if (town === 'other') {
					userInfo.location_name = locationName;
				} else {
					userInfo.index_city = index;
				}
			}

			mainService.setUserInfo(userInfo).then((data) => {
				if (data.error) {
					alert(data.error);
				} else {
					dispatch(setNeedInfo(data.need_info));
					dispatch(setBalance(data.balance));
					dispatch(
						setUser({
							firstName: data.user.F_NAME,
							id: data.user.ID,
							lastName: data.user.L_NAME,
							phone: data.user.MOB_PHONE,
						}),
					);
					history.push('/home');
				}
			});
		}
	};

	let cities = locationType === '1' ? towns[region] : pgts[region];

	return (
		<div className="container">
			<div className="user-info-header">
				<h2>{TEXT.welcome}!</h2>
				<Button variant="outlined" onClick={signOut}>
					<span>{TEXT.sign_out}</span>
				</Button>
			</div>
			<p className="mb-2em">
				{isUpdate
					? TEXT.user_info_description_update
					: TEXT.user_info_description}
			</p>
			<form onSubmit={formSubmit}>
				{/* Пол */}
				<div className="field">
					<FormControl
						component="fieldset"
						error={Boolean(genderError)}>
						<FormLabel component="legend">{TEXT.gender}</FormLabel>
						<RadioGroup
							row
							aria-label="position"
							name="position"
							value={gender}
							onChange={(e) => {
								setGender(e.target.value);
								setGenderError('');
							}}>
							<FormControlLabel
								value="1"
								control={<Radio color="primary" />}
								label={TEXT.gender_m}
								disabled={isLoading}
							/>
							<FormControlLabel
								value="2"
								control={<Radio color="primary" />}
								label={TEXT.gender_f}
								disabled={isLoading}
							/>
						</RadioGroup>
						<FormHelperText>{genderError}</FormHelperText>
					</FormControl>
				</div>

				{/* Фамилия */}
				<div className="field">
					<TextField
						fullWidth
						label={TEXT.l_name}
						required
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						autoComplete="name"
						disabled={isLoading}
					/>
				</div>

				{/* Имя */}
				<div className="field">
					<TextField
						fullWidth
						label={TEXT.f_name}
						required
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						autoComplete="name"
						disabled={isLoading}
					/>
				</div>

				{/* Отчество */}
				<div className="field">
					<TextField
						fullWidth
						label={TEXT.m_name}
						required
						value={middleName}
						onChange={(e) => setMiddleName(e.target.value)}
						autoComplete="name"
						disabled={isLoading}
					/>
				</div>

				{/* Дата рождения */}
				<div className="field">
					<MuiPickersUtilsProvider
						utils={DateFnsUtils}
						locale={ruLocale}>
						<Grid container justify="space-around">
							<DatePicker
								disableFuture
								openTo="year"
								views={['year', 'month', 'date']}
								fullWidth
								label={`${TEXT.date_birth} (ДД.ММ.ГГГГ)`}
								format="dd.MM.yyyy"
								value={birthDate}
								onChange={(date) => {
									setBirthDate(date);
									setBirthDateError('');
								}}
								required={true}
								cancelLabel={TEXT.cancel}
								maxDate={new Date('2006-01-01')}
								disabled={isLoading}
								error={Boolean(birthDateError)}
								helperText={birthDateError}
							/>
						</Grid>
					</MuiPickersUtilsProvider>
				</div>

				{/* Уровень образования*/}
				<div className="field">
					<FormControl fullWidth>
						<InputLabel htmlFor="education-level'">
							{TEXT.education_level}
						</InputLabel>
						<Select
							required
							fullWidth
							native
							onChange={(e) => setEducationLevel(e.target.value)}
							value={educationLevel}
							inputProps={{
								name: 'education_level',
								id: 'education-level',
							}}
							disabled={isLoading}>
							<option aria-label="None" value="" />
							{EDUCATION_LEVELS.map(({value, label}, index) => {
								return (
									<option value={value} key={value}>
										{label[lang]}
									</option>
								);
							})}
						</Select>
					</FormControl>
				</div>

				{/* Семейной положение */}
				<div className="field">
					<FormControl fullWidth>
						<InputLabel htmlFor="marital-status'">
							{TEXT.marital_status}
						</InputLabel>
						<Select
							required
							fullWidth
							native
							onChange={(e) => setMaritalStatus(e.target.value)}
							value={maritalStatus}
							inputProps={{
								name: 'marital_status',
								id: 'marital-status',
							}}
							disabled={isLoading}>
							<option aria-label="None" value="" />
							{MARITAL_STATUSES.map(({value, label}, index) => {
								return (
									<option value={value} key={value}>
										{label[lang]}
									</option>
								);
							})}
						</Select>
					</FormControl>
				</div>

				{/* Область */}
				<div className="field">
					<FormControl fullWidth>
						<InputLabel htmlFor="select-region">
							{TEXT.region}
						</InputLabel>
						<Select
							required
							fullWidth
							native
							value={region}
							onChange={(e) => {
								setRegion(e.target.value);
								setTown('');
								setIndex('');
							}}
							inputProps={{
								name: 'region',
								id: 'select-region',
							}}
							disabled={isLoading}>
							<option aria-label="None" value="" />
							{regions.map((value, index) => {
								return (
									<option
										value={value.value}
										key={value.value}>
										{value.label}
									</option>
								);
							})}
						</Select>
					</FormControl>
				</div>

				{/* Тип населённого пункта */}
				{region && (
					<div className="field">
						<FormControl fullWidth>
							<InputLabel htmlFor="location-type'">
								{TEXT.location_type}
							</InputLabel>
							<Select
								required
								fullWidth
								native
								onChange={(e) =>
									setLocationType(e.target.value)
								}
								value={locationType}
								inputProps={{
									name: 'location_type',
									id: 'location-type',
								}}
								disabled={isLoading}>
								<option aria-label="None" value="" />
								{LOCATION_TYPE.map(({value, label}, index) => {
									return (
										<option value={value} key={value}>
											{label[lang]}
										</option>
									);
								})}
							</Select>
						</FormControl>
					</div>
				)}

				{/* Город и посёлок городского типа */}
				{region && (locationType === '1' || locationType === '2') && (
					<div className="field">
						<FormControl fullWidth>
							<InputLabel htmlFor="age-native-simple">
								{locationType === '1'
									? TEXT.town
									: TEXT.pgt}
							</InputLabel>
							<Select
								fullWidth
								native
								required
								value={town}
								onChange={(e) => {
									setTown(e.target.value);
									setIndex('');
								}}
								inputProps={{
									name: 'age',
									id: 'age-native-simple',
								}}
								disabled={isLoading}>
								<option aria-label="None" value="" />
								<option value="other">{TEXT.other}</option>
								{cities.map((value) => {
									return (
										<option
											value={value.value}
											key={value.value}>
											{value.label}
										</option>
									);
								})}
							</Select>
						</FormControl>
					</div>
				)}

				{/* Название населенного пункта */}
				{(locationType === '3' || town === 'other') && (
					<div className="field">
						<TextField
							fullWidth
							label={TEXT.location_name}
							required
							value={locationName}
							onChange={(e) => setLocationName(e.target.value)}
							autoComplete="location"
							disabled={isLoading}
						/>
					</div>
				)}

				{/* Индекс */}
				{region &&
					(locationType === '1' || locationType === '2') &&
					town &&
					town !== 'other' && (
						<div className="field">
							<FormControl fullWidth>
								<InputLabel htmlFor="age-native-simple">
									{TEXT.index}
								</InputLabel>
								<Select
									fullWidth
									native
									required
									value={index}
									onChange={(e) => setIndex(e.target.value)}
									inputProps={{
										name: 'age',
										id: 'age-native-simple',
									}}
									disabled={isLoading}>
									<option aria-label="None" value="" />
									{generateIndexList(indexes[town]).map(
										(value, index) => {
											return (
												<option
													value={value.value}
													key={value.value}>
													{value.label}
												</option>
											);
										},
									)}
								</Select>
							</FormControl>
						</div>
					)}

				{/* Телефон */}
				<div className="field">
					<TextField
						fullWidth
						label={TEXT.phone_number}
						required
						value={phone}
						onChange={(e) => {
							setPhone(e.target.value);
							setPhoneError('');
						}}
						autoComplete="current-password"
						disabled={isLoading}
						InputProps={{
							inputComponent: TextMaskCustom,
						}}
						error={Boolean(phoneError)}
						helperText={phoneError}
					/>
				</div>

				{/* Есть ли у вас дети */}
				<div className="field">
					<FormControl
						component="fieldset"
						error={Boolean(hasChildError)}>
						<FormLabel component="legend">
							{TEXT.has_child}
						</FormLabel>
						<RadioGroup
							row
							aria-label="position"
							name="has-child"
							required
							value={hasChild}
							onChange={(e) => {
								setHasChild(e.target.value);
								setHasChildError('');
							}}>
							<FormControlLabel
								value="1"
								control={<Radio color="primary" />}
								label={TEXT.yes}
								disabled={isLoading}
							/>
							<FormControlLabel
								value="-1"
								control={<Radio color="primary" />}
								label={TEXT.no}
								disabled={isLoading}
							/>
						</RadioGroup>
						<FormHelperText>{hasChildError}</FormHelperText>
					</FormControl>
				</div>

				{/* Откуда вы о нас узнали */}
				<div className="field">
					<FormControl fullWidth>
						<InputLabel htmlFor="select-region">
							{TEXT.how_know}
						</InputLabel>
						<Select
							required
							fullWidth
							native
							onChange={(e) => setHowKnow(e.target.value)}
							value={howKnow}
							inputProps={{
								name: 'region',
								id: 'select-region',
							}}
							disabled={isLoading}>
							<option aria-label="None" value="" />
							{HOW_KNOW.map(({value, label}, index) => {
								return (
									<option value={value} key={value}>
										{label[lang]}
									</option>
								);
							})}
						</Select>
					</FormControl>
				</div>

				{/* Политика конфиденциальности */}
				<div className="field">
					<FormControl required component="fieldset">
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={agreement}
										onChange={(e) =>
											setAgreement(e.target.checked)
										}
										color="primary"
										required
									/>
								}
								label={
									<Fragment>
										{TEXT.agree + ' '}
										<a
											href={DOMAIN + 'agreement.html'}
											target="_blank"
											rel="noopener noreferrer">
											{TEXT.agreement}
										</a>
									</Fragment>
								}
							/>
						</FormGroup>
					</FormControl>
				</div>
				<div className="text-center">
					<CustomButton
						text={TEXT.save}
						classNames="btn-colored"
						isLoading={isButtonLoading}
						isDisabled={isButtonLoading || isLoading}
					/>
				</div>
			</form>
		</div>
	);
};

export default WithMainService()(UserInfoPage);
