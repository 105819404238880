import React from 'react';

// Material Components
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const WithdrawHistoryIcon = ({status}) => {
	// 0 - canceled, 1 - success, 2 - wait
	switch (status) {
		case '0':
			return (
				<span className="warning">
					<AccessTimeIcon />
				</span>
			);
		case '1':
			return (
				<span className="success">
					<CheckIcon />
				</span>
			);
		case '2':
			return (
				<span className="cancel">
					<CloseIcon />
				</span>
			);
		default:
			return;
	}
};

export default WithdrawHistoryIcon;
