import React, {useState} from 'react';

// Service
import WithMainService from '../hoc/WithMainService';

// Material components
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

// Components
import Button from '../Button';
import FormError from '../FormError';

// Functions
import {validateEmail} from '../../core/common-functions';

// Redux
import {useSelector} from 'react-redux';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const ResetPasswordForm = ({mainService, setSnack}) => {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(null);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(null);

	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const setFormState = (state = true) => {
		setIsLoading(state);
		setIsButtonLoading(state);
	};

	const onResetPassword = (e) => {
		e.preventDefault();
		setFormError(null);
		setFormSuccess(null);
		if (!validateEmail(email)) {
			setEmailError(ERR_TEXT[1001]);
			return;
		}
		setFormState();
		mainService
			.resetPassword({email})
			.then((response) => {
				if (response.error) {
					if (response.code) {
						setFormError(ERR_TEXT[response.code]);
					}
				} else if (response.success) {
					setEmail('');
					setFormSuccess(true);
				}
				setFormState(false);
			})
			.catch((err) => {
				setSnack({
					isOpen: true,
					text: ERR_TEXT.something_went_wrong,
				});
				setFormState(false);
			});
	};

	return (
		<div className="m-auto max-width-500">
			<form onSubmit={onResetPassword}>
				<div className="field">
					<TextField
						disabled={isLoading}
						fullWidth
						error={Boolean(emailError)}
						label="Email"
						helperText={emailError}
						type="email"
						required
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setEmailError(null);
						}}
						autoComplete="user-name"
					/>
				</div>
				<div className="text-center">
					<Button
						text={TEXT.reset_password_action}
						classNames="btn-colored"
						isLoading={isButtonLoading}
						isDisabled={isButtonLoading || isLoading}
					/>
				</div>
			</form>
			<Collapse in={Boolean(formSuccess)}>
				<Alert severity="success" className="mt-2em">
					{TEXT.reset_password_action_success}
				</Alert>
			</Collapse>
			<FormError text={formError} className="mt-2em" />
		</div>
	);
};

export default WithMainService()(ResetPasswordForm);
