import React, {useState} from 'react';
import {Link} from 'react-router-dom';

// Service
import WithMainService from '../hoc/WithMainService';

// Material components
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

// Components
import Button from '../Button';
import FormError from '../FormError';

// Redux
import {useSelector} from 'react-redux';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const SetNewPasswordForm = ({setSnack, mainService, recoveryCode}) => {
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(null);
	const [repeatPassword, setRepeatPassword] = useState('');
	const [repeatPasswordError, setRepeatPasswordError] = useState(null);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(null);

	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const setFormState = (state = true) => {
		setIsLoading(state);
		setIsButtonLoading(state);
	};

	const onResetPassword = (e) => {
		e.preventDefault();
		setFormSuccess(null);
		setFormError(null);
		setPasswordError(null);
		setRepeatPasswordError(null);
		if (password !== repeatPassword) {
			setPasswordError(ERR_TEXT.repeat_pass);
			setRepeatPasswordError(ERR_TEXT.repeat_pass);
			return;
		}
		setFormState();
		mainService
			.setNewPassword({
				password,
				recovery_code: recoveryCode,
			})
			.then((response) => {
				if (response.error) {
					setFormError(ERR_TEXT[response.code]);
				} else if (response.success) {
					setFormSuccess(true);
				}
				setPassword('');
				setRepeatPassword('');
				setFormState(false);
			})
			.catch((err) => {
				setSnack({
					isOpen: true,
					text:
						'Что-то пошло не так. Попробуйте, пожалуйста, немного позже.',
				});
				setFormState(false);
			});
	};
	return (
		<div className="m-auto max-width-500">
			{!formSuccess && (
				<form onSubmit={onResetPassword}>
					<div className="field">
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(passwordError)}
								label={TEXT.input_pass_text}
								helperText={passwordError}
								type="password"
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(repeatPasswordError)}
								label={TEXT.repeat_pass_text}
								helperText={repeatPasswordError}
								type="password"
								required
								value={repeatPassword}
								onChange={(e) =>
									setRepeatPassword(e.target.value)
								}
							/>
						</div>
					</div>
					<div className="text-center">
						<Button
							text={TEXT.save}
							classNames="btn-colored"
							isLoading={isButtonLoading}
							isDisabled={isButtonLoading || isLoading}
						/>
					</div>
				</form>
			)}
			<Collapse in={Boolean(formSuccess)}>
				<Alert severity="success" className="mt-2em">
					{TEXT.new_password_set_success}{' '}
					<Link to="/sign-in">{TEXT.sign_in_to_panel}</Link>
				</Alert>
			</Collapse>
			<FormError text={formError} className="mt-2em" />
		</div>
	);
};

export default WithMainService()(SetNewPasswordForm);
