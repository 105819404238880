import React from 'react';

// Redux
import {useSelector} from 'react-redux';

// Components
import WithdrawHistoryItem from './WithdrawHistoryItem';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const WithdrawHistory = ({data}) => {
	const lang = useSelector((state) => state.app.lang);
	const {charity} = APP_TEXTS[lang];

	const dataLength = Object.keys(data).length;

	if (dataLength > 0) {
		return (
			<div className="modal-data-list">
				{Object.keys(data).map((key, index) => {
					const value = data[key];
					const date = new Date(key);
					return (
						<WithdrawHistoryItem
							index={index}
							value={value}
							date={date}
							lang={lang}
							charity={charity}
							key={index}
						/>
					);
				})}
			</div>
		);
	} else {
		return <div className="modal-data-list--empty">Пусто</div>;
	}
};

export default WithdrawHistory;
