import React from 'react';

// Material components
import Button from '@material-ui/core/Button';

// Images
import logo from '../../images/svg/logo-vertical.svg';
import coin from '../../images/svg/coin.svg';

const PollThanks = ({history, coinCount, text}) => {
	return (
		<div className="thanks-poll">
			<img src={logo} alt="Lemur" width="100" />
			<h3>{text.poll_thanks}</h3>
			{coinCount > 0 && (
				<h4>
					{text.poll_accrued}: {coinCount}
					<img
						src={coin}
						alt="Lemur Coin"
						width="16"
						className="coin"
					/>
				</h4>
			)}
			<Button
				variant="outlined"
				color="primary"
				onClick={() => history.push('/home')}>
				{text.go_to_panel}
			</Button>
		</div>
	);
};

export default PollThanks;
