import React from 'react';

const AvailablePollsEmpty = ({texts}) => {
	return (
		<div className="vertical-centered">
			<h4>{texts.empty}</h4>
		</div>
	);
};

export default AvailablePollsEmpty;
