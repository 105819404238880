import React, {useState, useEffect} from 'react';

// Material components
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

// Components
import ResetPasswordForm from '../ResetPasswordForm';
import SetNewPasswordForm from '../SetNewPasswordForm';

// Redux
import {useSelector} from 'react-redux';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const ResetPasswordPage = ({match}) => {
	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	const [recoveryCode, setRecoveryCode] = useState(null);
	const [snack, setSnack] = useState({
		isOpen: false,
		text: '',
	});

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnack({
			isOpen: false,
			text: '',
		});
	};

	useEffect(() => {
		const {
			params: {recoveryCode: matchCode},
		} = match;
		if (typeof matchCode !== 'undefined') {
			setRecoveryCode(matchCode);
		}
	}, [match]);

	const Form = () => {
		if (recoveryCode) {
			return (
				<SetNewPasswordForm
					setSnack={setSnack}
					recoveryCode={recoveryCode}
				/>
			);
		} else {
			return <ResetPasswordForm setSnack={setSnack} />;
		}
	};

	return (
		<div className="container">
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={snack.isOpen}
				onClose={handleSnackClose}
				message={snack.text}
				TransitionComponent={Slide}
				autoHideDuration={3000}
			/>
			<h2 className="text-center">
				{recoveryCode ? TEXT.set_password : TEXT.reset_password}
			</h2>
			<div className="reset-password-form">
				<Form />
			</div>
		</div>
	);
};

export default ResetPasswordPage;
