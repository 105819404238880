import {createStore, combineReducers} from 'redux';
import {dataReducer} from './reducers/data';
import {appReducer} from './reducers/app';

const rootReducer = combineReducers({
	data: dataReducer,
	app: appReducer,
});

export default createStore(rootReducer);
