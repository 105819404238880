import React from 'react';
import {Link} from 'react-router-dom';

// Redux
import {useSelector} from 'react-redux';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const AuthAnotherActionBlock = ({isLogin}) => {
	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	const mainText = isLogin
		? {
				header: TEXT.sign_in_right_block_head,
				text: TEXT.sign_in_right_block_text,
		  }
		: {
				header: TEXT.sign_up_right_block_head,
				text: TEXT.sign_up_right_block_text,
		  };
	const link = {
		to: isLogin ? 'sign-up' : 'sign-in',
		text: isLogin ? TEXT.sign_up_2 : TEXT.sign_in,
	};

	return (
		<div className="another-action text-center">
			<h5>{mainText.header}</h5>
			<p className="mb-2em">{mainText.text}</p>
			<Link className="btn btn-colored-2" to={link.to}>
				{link.text}
			</Link>
		</div>
	);
};

export default AuthAnotherActionBlock;
