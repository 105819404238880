import React from 'react';

// Material components
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

const FormError = ({text, className}) => {
	return (
		<Collapse in={Boolean(text)}>
			<Alert severity="error" className={className}>
				{text}
			</Alert>
		</Collapse>
	);
};

export default FormError;
