import {API_BASE_URL} from '../core/constants';
import store from '../store';

export default class MainService {
	async getResource(url, body = {}) {
		const authToken = store.getState().app.authToken;
		if (authToken) {
			body.token = authToken;
		}
		const request = await fetch(API_BASE_URL + url, {
			method: 'POST',
			body: JSON.stringify(body),
		})
			.then((data) => data.json())
			.then((json) => json)
			.catch((error) => {
				throw new Error(`Could not fetch ${url}, received ${error}`);
			});
		return request;
	}

	signIn(body) {
		return this.getResource('login', body);
	}

	signUp(body) {
		return this.getResource('register', body);
	}

	logOut() {
		return this.getResource('logout');
	}

	setLang(body) {
		return this.getResource('set_lang', body);
	}

	resetPassword(body) {
		return this.getResource('reset_password_link', body);
	}

	setNewPassword(body) {
		return this.getResource('reset_password_set', body);
	}

	setNotificationToken(body) {
		return this.getResource('notification_token', body);
	}

	setUserInfo(body) {
		return this.getResource('update_user_info', body);
	}

	withdraw(body) {
		return this.getResource('withdraw', body);
	}

	getAvailablePolls() {
		return this.getResource('available_polls');
	}

	getPoll(body) {
		return this.getResource('get_poll', body);
	}

	startPoll(body) {
		return this.getResource('start_poll', body);
	}

	getEnrollmentHistory() {
		return this.getResource('enrollment_history');
	}

	getWithdrawHistory() {
		return this.getResource('withdraw_history');
	}

	getUser() {
		return this.getResource('get_user');
	}

	getUserInfo() {
		return this.getResource('get_user_info');
	}
}
