import React, {useEffect, useState} from 'react';

// Redux
import {useSelector} from 'react-redux';

// Components
import PollCard from '../PollCard';

// HOC
import WithMainService from '../hoc/WithMainService';

// JSON
import ERROR_TEXTS from '../../core/json/errors.json';

const PollPage = ({match, mainService, history}) => {
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [poll, setPoll] = useState({});
	const [error, setError] = useState(false);
	const [selectedLang, setSelectedLang] = useState('');
	const [formError, setFormError] = useState('');

	// Redux
	const lang = useSelector((state) => state.app.lang);

	const ERR_TEXT = ERROR_TEXTS[lang];

	useEffect(() => {
		mainService
			.getPoll({poll_id: match.params.pollID})
			.then((data) => {
				if (data.error) {
					setError(true);
				} else {
					setPoll(data);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [match.params.pollID, mainService]);

	const startPoll = () => {
		let fetchData = {
			poll_id: match.params.pollID,
		};
		if (poll.poll_langs.length > 1 && poll.status === 1) {
			if (selectedLang) {
				fetchData.poll_lang = selectedLang;
			} else {
				setFormError(ERR_TEXT.start_poll_lang);
				return false;
			}
		}
		setIsButtonLoading(true);
		mainService
			.startPoll(fetchData)
			.then((data) => {
				if (data.link) {
					document.location = data.link;
				} else {
					if(data.error) {
						if(data.code) {
							setFormError(ERR_TEXT[data.code]);
						} else {
							setFormError(data.error);
						}
					}
					setIsButtonLoading(false);
				}
			})
			.catch((error) => {
				setIsButtonLoading(false);
			});
	};

	return (
		<div className="container">
			<div className="poll-card">
				<PollCard
					history={history}
					isLoading={isLoading}
					error={error}
					poll={poll}
					selectedLang={selectedLang}
					setSelectedLang={setSelectedLang}
					formError={formError}
					setFormError={setFormError}
					isButtonLoading={isButtonLoading}
					startPoll={startPoll}
					lang={lang}
				/>
			</div>
		</div>
	);
};

export default WithMainService()(PollPage);
