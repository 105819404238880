import React from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';

// Redux
import {useSelector} from 'react-redux';

// Images
import logo from '../../images/svg/logo.svg';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const langs = ['ru', 'ua'];

const Header = ({authToken, onChangeLang}) => {
	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	const {pathname} = useLocation();
	const history = useHistory();

	const HeaderActions = () => {
		return (
			<Button
				variant="outlined"
				className="user-button"
				onClick={() => {
					if (authToken) {
						if (pathname !== '/home') {
							history.push('/home');
						}
					} else if (pathname !== '/sign-in') {
						history.push('/sign-in');
					}
				}}>
				<span>{authToken ? TEXT.kabinet : TEXT.sign_in}</span>
			</Button>
		);
	};

	return (
		<header>
			<div className="container">
				<div className="header-content flex space-between align-center">
					<Link className="logo" to={'/'}>
						<img src={logo} alt="Lemur" width="130" />
					</Link>
					<div className="right-side flex align-center">
						<div className="langs">
							{langs.map((value, index) => (
								<span
									key={index}
									onClick={() => {
										if (lang !== value) {
											onChangeLang(value);
										}
									}}
									className={lang === value ? 'active' : ''}>
									{value.toUpperCase()}
								</span>
							))}
						</div>
						<div className="auth-block">
							<HeaderActions />
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
