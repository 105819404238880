import React, {useEffect, useState, useCallback} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {
	HomePage,
	StartPage,
	SignInPage,
	WithdrawPage,
	NoMatchPage,
	UserInfoPage,
	ResetPasswordPage,
	PollPage,
} from '../pages';

import PrivateRoute from './PrivateRoute';
import NotAuthRoute from './NotAuthRoute';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {
	setAuthToken,
	setUser,
	setNeedInfo,
	setBalance,
	setLang,
	setPathname,
} from '../../store/actions/app';
import SignUpPage from '../pages/SignUpPage';

// HOC
import WithMainService from '../hoc/WithMainService';

// Components
import AppLoader from '../AppLoader';
import Header from '../Header';
import Footer from '../Footer';

// Common functions
import {setHTMLDocumentLang} from '../../core/common-functions';

const App = ({mainService}) => {
	// Redux
	const authToken = useSelector((state) => state.app.authToken);
	const dispatch = useDispatch();

	const history = useHistory();

	// Входной pathname
	const pathname = window.location.pathname;

	const [isLoading, setIsLoading] = useState(true);

	const signOut = useCallback(() => {
		mainService.logOut().then((response) => {
			localStorage.removeItem('authToken');
			dispatch(setAuthToken(null));
			dispatch(
				setUser({
					firstName: null,
					id: null,
					lastName: null,
					phone: null,
				}),
			);
			dispatch(setNeedInfo(null));
			dispatch(setBalance(null));
			dispatch(setPathname(null));
		});
	}, [dispatch, mainService]);

	useEffect(() => {
		const localStorageToken = localStorage.getItem('authToken');
		if (localStorageToken) {
			dispatch(setAuthToken(localStorageToken));
			mainService.getUser().then((data) => {
				if (data.error) {
					signOut();
				} else {
					if (data.user) {
						dispatch(setNeedInfo(data.need_info));
						dispatch(setBalance(data.balance));
						if (data.user.LANG) {
							dispatch(setLang(data.user.LANG));
							setHTMLDocumentLang(data.user.LANG);
						}
						dispatch(
							setUser({
								firstName: data.user.F_NAME,
								id: data.user.ID,
								lastName: data.user.L_NAME,
								phone: data.user.MOB_PHONE,
							}),
						);
					}
				}
				setIsLoading(false);
			});
		} else {
			dispatch(setPathname(pathname));
			setIsLoading(false);
		}

		// Всё что ниже надо для того, чтобы переходя на другую страницу прокрутка была на начале страницы
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return unlisten;
	}, [signOut, dispatch, mainService, history, pathname]);

	const onChangeLang = (lang) => {
		if(authToken) {
			mainService.setLang({lang});
		}
		dispatch(setLang(lang));
		setHTMLDocumentLang(lang);
	};

	if (isLoading) {
		return <AppLoader />;
	} else {
		return (
			<div className="wrapper">
				<Header authToken={authToken} onChangeLang={onChangeLang} />
				<div className="content">
					<Switch>
						<Route path="/" component={StartPage} exact />

						{/* Only auth users */}
						<PrivateRoute
							path="/user-info"
							component={() => <UserInfoPage signOut={signOut} />}
							exact
						/>
						<PrivateRoute
							path="/home"
							component={() => <HomePage signOut={signOut} />}
							exact
						/>
						<PrivateRoute
							path="/poll/:pollID"
							component={PollPage}
							exact
						/>
						<PrivateRoute
							path="/withdraw"
							component={WithdrawPage}
							exact
						/>
						{/* END Only auth users */}

						{/* Only don't auth users */}
						<NotAuthRoute
							path="/sign-in"
							component={SignInPage}
							exact
						/>
						<NotAuthRoute
							path="/sign-up"
							component={SignUpPage}
							exact
						/>
						<NotAuthRoute
							path="/reset-password"
							component={ResetPasswordPage}
							exact
						/>
						{/* END Only don't auth users */}

						<Route
							path="/reset-password/:recoveryCode"
							component={ResetPasswordPage}
							exact
						/>
						<Route component={NoMatchPage} />
					</Switch>
				</div>
				<Footer />
			</div>
		);
	}
};

export default WithMainService()(App);
