import {createContext} from 'react';

const {
    Provider: MainServiceProvider,
    Consumer: MainServiceConsumer
} = createContext();

export {
    MainServiceProvider,
    MainServiceConsumer
};