import React from 'react';

// Material Components
import Button from '@material-ui/core/Button';

// Images
import coin from '../../images/svg/coin.svg';

const AvailablePollsItem = ({history, poll, texts, lang}) => {
	const {poll_name: name, poll_id: id, price, status} = poll;
	return (
		<tr key={id}>
			<td>{name[lang] ? name[lang] : name[Object.keys(name)[0]]}</td>
			<td>
				{price > 0 && (
					<div className="coin-count">
						<span>{price}</span>
						<img src={coin} alt="Лемур" width="16" />
					</div>
				)}
			</td>
			<td>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => history.push(`/poll/${id}`)}
					className={`poll-status-${status}`}>
					{(status === 1 ? texts.start : texts.resume) +
						' ' +
						texts.poll}
				</Button>
			</td>
		</tr>
	);
};

export default AvailablePollsItem;
