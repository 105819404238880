import React from 'react';
// Images
import app from '../../images/app.png';
import appStore from '../../images/svg/app-store.svg';
import googlePlay from '../../images/svg/google-play.svg';

const AppBlock = ({text}) => {
	return (
		<div className="app-block">
			<h2>{text.app_head}<br />iOS и Android</h2>
            <p>
                {text.app_description}
            </p>
			<div className="app-block__inner">
				<div className="app-block-image">
					<img src={app} alt="Lemur App" />
				</div>
                <div className="available-app">
                    <a href="https://play.google.com/store/apps/details?id=ua.lemur.panel" target="_blank" rel="noopener noreferrer" title="Lemur on the Google Play">
                        <img src={googlePlay} alt="Google Play" />
                    </a>
                    <a href="https://apps.apple.com/ua/app/lemur/id1536908766" target="_blank" rel="noopener noreferrer" title="Lemur on the App Store">
                        <img src={appStore} alt="App Store" />
                    </a>
                </div>
			</div>
		</div>
	);
};

export default AppBlock;
