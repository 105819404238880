import React, {Fragment, useState} from 'react';

// Images
import google from '../../images/svg/google.svg';
import facebook from '../../images/svg/facebook.svg';

// Social
import {useGoogleLogin} from 'react-google-login';
import FacebookAuth from 'react-facebook-auth';

// Components
import Spinner from '../Spinner';

// Redux
import {useSelector} from 'react-redux';

const SocialAuth = ({
	event,
	isLoading,
	setIsLoading,
	setFormError,
	errText,
}) => {
	const [facebookLoading, setFacebookLoading] = useState(false);
	const [googleLoading, setGoogleLoading] = useState(false);

	// Redux
	const lang = useSelector((state) => state.app.lang);

	const responseGoogle = (response) => {
		if (!response.error) {
			let accessToken = response.accessToken || response.getAuthResponse().access_token;
			event('google', accessToken).catch((err) => {
				setGoogleLoading(false);
			});
		}
	};

	const responseFacebook = (response) => {
		if (response.accessToken) {
			if (response.email) {
				event('facebook', response.accessToken).catch((err) => {
					setFacebookLoading(false);
				});
			} else {
				setIsLoading(false);
				setFacebookLoading(false);
				setFormError(errText.facebook_email);
			}
		} else {
			setIsLoading(false);
			setFacebookLoading(false);
		}
	};

	const googleFailure = () => {
		setIsLoading(false);
		setGoogleLoading(false);
	};

	const {signIn: googleLogin} = useGoogleLogin({
		onSuccess: responseGoogle,
		onFailure: googleFailure,
		clientId:
			'870636383721-dnr3b01eno476u3ee43ke3dhkmiq0ukr.apps.googleusercontent.com',
	});

	const facebookClick = (onClick) => {
		if (!facebookLoading && !isLoading) {
			setFormError('');
			setIsLoading(true);
			setFacebookLoading(true);
			onClick();
		}
	};

	const googleClick = () => {
		if (!googleLoading && !isLoading) {
			setIsLoading(true);
			setGoogleLoading(true);
			googleLogin();
		}
	};

	const FacebookButton = ({onClick}) => {
		return (
			<span
				className={'facebook ' + (facebookLoading ? 'loading' : '')}
				onClick={() => facebookClick(onClick)}>
				<Spinner />
				<img src={facebook} alt="Facebook" />
			</span>
		);
	};

	return (
		<Fragment>
			<div className="bordered-text up mt-3em mb-2em max-width-400 m-auto">
				{lang === 'ru' ? 'или' : 'або'}
			</div>
			<div className="social-auth">
				<FacebookAuth
					appId="1248192688900026"
					callback={responseFacebook}
					component={FacebookButton}
					reRequest
					fields="name,email,picture"
					scope="public_profile,email"
					disableRedirect
				/>
				<span
					className={'google ' + (googleLoading ? 'loading' : '')}
					onClick={googleClick}>
					<Spinner />
					<img src={google} alt="Google" />
				</span>
			</div>
		</Fragment>
	);
};

export default SocialAuth;
