// Data types
const SET_ENROLLMENT_HISTORY = 'SET_ENROLLMENT_HISTORY';
const SET_WITHDRAW_HISTORY = 'SET_WITHDRAW_HISTORY';

// App types
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const SET_USER = 'SET_USER';
const SET_BALANCE = 'SET_BALANCE';
const SET_NEED_INFO = 'SET_NEED_INFO';
const SET_LANG = 'SET_LANG';
const SET_PATHNAME = 'SET_PATHNAME';

export {
	SET_ENROLLMENT_HISTORY,
	SET_WITHDRAW_HISTORY,
	// App
	SET_AUTH_TOKEN,
	SET_USER,
	SET_BALANCE,
	SET_NEED_INFO,
	SET_LANG,
	SET_PATHNAME,
};
