import {SET_ENROLLMENT_HISTORY, SET_WITHDRAW_HISTORY} from '../types';

const initialState = {
	enrollmentHistory: {
		data: [],
		need_update: true,
	},
	withdrawHistory: {
		data: [],
		need_update: true,
	},
};

export const dataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ENROLLMENT_HISTORY:
			return {...state, enrollmentHistory: action.payload};
		case SET_WITHDRAW_HISTORY:
			return {...state, withdrawHistory: action.payload};
		default:
			return state;
	}
};
