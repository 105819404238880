import React, {Component} from 'react';

const ErrorIndicator = () => <div>Ошибка в компоненте</div>;

export default class ErrorBoundary extends Component {
    state = {
        hasError: false
    };

    componentDidCatch() {
        this.setState({hasError: true});
    }

    render() {
        if(this.state.hasError) {
            return <ErrorIndicator />;
        }
        return this.props.children;
    }
}