import React from 'react';

// Other components
import InputMask from 'react-input-mask';

const TextMaskCustom = (props) => {
	const {inputRef, ...other} = props;

	return (
		<InputMask
			mask="+38 (099) 99 99 999"
			required
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			beforeMaskedStateChange={beforeMaskedStateChange}
		/>
	);
};

function beforeMaskedStateChange({nextState}) {
	let {value} = nextState;
	if (value.endsWith('/')) {
		value = value.slice(0, -1);
	}

	return {
		...nextState,
		value,
	};
}

export default TextMaskCustom;
