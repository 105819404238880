import React from 'react';

// Redux
import {useSelector} from 'react-redux';

// JSON
import APP_TEXTS from '../../core/json/langs.json';

const Footer = () => {
	// Redux
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];

	return (
		<footer>
			<div className="container">
				<div className="footer-inner">
					<div className="footer-item">
						{TEXT.footer_item}:{' '}
						<a href="mailto:admin@lemur.ua">
							admin@lemur.ua
						</a>
					</div>
					<div className="footer-item">
						{TEXT.footer_item_2}:{' '}
						<a href="mailto:admin@lemur.ua">
							admin@lemur.ua
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
