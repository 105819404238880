import React from 'react';

// Components
import PollLoading from './PollLoading';
import PollError from './PollError';
import PollInfo from './PollInfo';
import PollThanks from './PollThanks';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const PollCard = ({
	history,
	isLoading,
	error,
	poll,
	selectedLang,
	setSelectedLang,
	formError,
	setFormError,
	isButtonLoading,
	startPoll,
	lang,
}) => {
	const {poll_empty} = ERR_TEXTS[lang];
	const TEXT = APP_TEXTS[lang];

	// Загрузка
	if (isLoading) {
		return <PollLoading text={TEXT.poll_loading} />;
	} else {
		// Опрос не найден или выключен
		if (error) {
			return <PollError text={poll_empty} />;
		} else {
			// Если статус опроса не начат или начат
			if (poll.status === 1 || poll.status === 2) {
				return (
					<PollInfo
						poll={poll}
						selectedLang={selectedLang}
						setSelectedLang={setSelectedLang}
						formError={formError}
						setFormError={setFormError}
						isButtonLoading={isButtonLoading}
						startPoll={startPoll}
						lang={lang}
						text={TEXT}
					/>
				);
			} else {
				return (
					<PollThanks
						history={history}
						coinCount={parseInt(poll.count_ball)}
						lang={lang}
						text={TEXT}
					/>
				);
			}
		}
	}
};

export default PollCard;
