import React, {useState} from 'react';
import WithMainService from '../hoc/WithMainService';

// Material components
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

// Components
import Button from '../Button';
import SocialAuth from '../SocialAuth';
import AuthAnotherActionBlock from '../AuthAnotherActionBlock';
import FormError from '../FormError';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {
	setAuthToken,
	setUser,
	setBalance,
	setNeedInfo,
	setLang,
} from '../../store/actions/app';

// Functions
import {setHTMLDocumentLang} from '../../core/common-functions';

// JSON
import APP_TEXTS from '../../core/json/langs.json';
import ERR_TEXTS from '../../core/json/errors.json';

const SignInPage = ({mainService, history}) => {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(null);
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(null);
	const [repeatPassword, setRepeatPassword] = useState('');
	const [repeatPasswordError, setRepeatPasswordError] = useState(null);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState('');
	const [snack, setSnack] = useState({
		isOpen: false,
		text: '',
	});
	// Redux
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.app.lang);

	// TEXTS
	const TEXT = APP_TEXTS[lang];
	const ERR_TEXT = ERR_TEXTS[lang];

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnack({
			isOpen: false,
			text: '',
		});
	};

	const signUp = (type, token, data) => {
		setIsLoading(true);
		let userData = {
			type,
		};

		if (type === 'email') {
			userData = {
				...userData,
				email: data.email,
				password: data.password,
			};
		} else {
			userData.social_token = token;
		}

		return new Promise((resolve, reject) => {
			mainService
				.signUp(userData)
				.then((data) => {
					if (data.error) {
						reject(data);
						setIsLoading(false);
					} else {
						dispatch(setNeedInfo(data.need_info));
						dispatch(setBalance(data.balance));
						if (data.user.LANG) {
							dispatch(setLang(data.user.LANG));
							setHTMLDocumentLang(data.user.LANG);
						}
						dispatch(
							setUser({
								firstName: data.user.F_NAME,
								id: data.user.ID,
								lastName: data.user.L_NAME,
								phone: data.user.MOB_PHONE,
							}),
						);
						localStorage.setItem('authToken', data.token);
						dispatch(setAuthToken(data.token));
					}
				})
				.catch((err) => {
					reject(err);
					setSnack({
						isOpen: true,
						text: ERR_TEXT.something_went_wrong,
					});
					setIsLoading(false);
				});
		});
	};

	const onEmailSignUp = (e) => {
		e.preventDefault();
		setFormError('');
		if (password !== repeatPassword) {
			setPasswordError(ERR_TEXT.repeat_pass);
			setRepeatPasswordError(ERR_TEXT.repeat_pass);
			return;
		} else {
			setIsButtonLoading(true);
			signUp('email', null, {email, password}).catch((error) => {
				if (error.code) {
					if (error.code === 1002) {
						setEmailError(ERR_TEXT[error.code]);
					} else {
						setFormError(ERR_TEXT[error.code]);
					}
				}
				setIsButtonLoading(false);
			});
		}
	};

	return (
		<div className="container">
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={snack.isOpen}
				onClose={handleSnackClose}
				message={snack.text}
				TransitionComponent={Slide}
				autoHideDuration={3000}
			/>
			<h2 className="text-center">{TEXT.sign_up_2}</h2>
			<div className="auth-pages">
				<div className="auth-form">
					<form
						className="m-auto max-width-900"
						onSubmit={onEmailSignUp}>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(emailError)}
								label="Email"
								helperText={emailError}
								type="email"
								required
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									setEmailError('');
								}}
							/>
						</div>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(passwordError)}
								label={TEXT.input_pass_text}
								helperText={passwordError}
								type="password"
								required
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
									setPasswordError('');
									setRepeatPasswordError('');
								}}
							/>
						</div>
						<div className="field">
							<TextField
								disabled={isLoading}
								fullWidth
								error={Boolean(repeatPasswordError)}
								label={TEXT.repeat_pass_text}
								helperText={repeatPasswordError}
								type="password"
								required
								value={repeatPassword}
								onChange={(e) => {
									setRepeatPassword(e.target.value);
									setPasswordError('');
									setRepeatPasswordError('');
								}}
							/>
						</div>
						<div className="text-center">
							<Button
								text={TEXT.sign_up}
								classNames="btn-colored"
								isLoading={isButtonLoading}
								isDisabled={isButtonLoading || isLoading}
							/>
						</div>
						<FormError text={formError} className="mt-2em" />
					</form>
					<SocialAuth
						event={signUp}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						setFormError={setFormError}
						errText={ERR_TEXT}
					/>
				</div>
				<AuthAnotherActionBlock />
			</div>
		</div>
	);
};

export default WithMainService()(SignInPage);
