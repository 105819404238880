import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 500 500" width={500} height={500} {...props}>
      <g
        className="prefix__animable"
        id="prefix__background-simple--inject-1"
        style={{
          transformOrigin: "239.952px 236.605px",
        }}
      >
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__el1gyzepi2bj4"
          d="M415.83 230.1a201.81 201.81 0 00-33.63-95.78c-30.74-45.45-80.91-70-135.49-56.62C195.1 90.36 182 146.24 139 171c-33 19-83 50-75 105 2.61 17.93 14.48 31 30.76 38.37 22.26 10.05 53.39 6 73.24 20.63 23 17 42 41 92 59 39.55 14.24 84.15-2.8 114-29.75 36.82-33.17 46-86.66 41.83-134.15z"
          style={{
            transformOrigin: "239.952px 236.605px",
          }}
        />
        <path
          className="prefix__animable"
          d="M415.83 230.1a201.81 201.81 0 00-33.63-95.78c-30.74-45.45-80.91-70-135.49-56.62C195.1 90.36 182 146.24 139 171c-33 19-83 50-75 105 2.61 17.93 14.48 31 30.76 38.37 22.26 10.05 53.39 6 73.24 20.63 23 17 42 41 92 59 39.55 14.24 84.15-2.8 114-29.75 36.82-33.17 46-86.66 41.83-134.15z"
          style={{
            transformOrigin: "239.952px 236.605px",
          }}
          fill="#fff"
          opacity={0.7}
          id="prefix__el7tdkqte5h4q"
        />
      </g>
      <g
        className="prefix__animable"
        id="prefix__Shadow--inject-1"
        style={{
          transformOrigin: "373.57px 379.741px",
        }}
      >
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__elhmfiezd3h7t"
          d="M430.44 410.52l-6.8-6.72c5.66-8.2 3.22-14.24-1.64-19.8-2.93-3.35-10.59-2.84-20-1.63L350 331l-8 3s21.64 26.35 41.91 50.58c-8.42.75-16.89.76-23.91-1.58-21-7-38-22-38-22-24 2 12 41 26 52 28 22 42.88 18 62.76 3.18 3.57 4.08 6.15 6.89 7.24 7.82l.21.18c8.96 7.44 20.51-5.47 12.23-13.66z"
          style={{
            transformOrigin: "373.62px 379.7px",
          }}
        />
        <path
          className="prefix__animable"
          d="M430.44 410.52l-6.8-6.72c5.66-8.2 3.22-14.24-1.64-19.8-2.93-3.35-10.59-2.84-20-1.63L350 331l-8 3s21.64 26.35 41.91 50.58c-8.42.75-16.89.76-23.91-1.58-21-7-38-22-38-22-24 2 11.24 42.05 26 52 33 22.22 42.88 18 62.76 3.18 3.57 4.08 6.15 6.89 7.24 7.82l.21.18c8.96 7.44 20.51-5.47 12.23-13.66z"
          style={{
            transformOrigin: "373.57px 379.741px",
          }}
          fill="#fff"
          opacity={0.5}
          id="prefix__elrjpab1gq2fs"
        />
      </g>
      <g
        className="prefix__animable"
        id="prefix__Plants--inject-1"
        style={{
          transformOrigin: "113.522px 301.629px",
        }}
      >
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__elq32shd61p4a"
          d="M112.71 229.31s26.3 30.4 26.44 68.06-25.92 68.31-25.92 68.31-26.3-30.4-26.44-68.05 25.92-68.32 25.92-68.32z"
          style={{
            transformOrigin: "112.97px 297.495px",
          }}
        />
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__elbdh4kpvb1i"
          d="M170.5 315.45s.16 24.75-13.13 40.48-35 16.49-35 16.49-.17-24.75 13.13-40.48 35-16.49 35-16.49z"
          style={{
            transformOrigin: "146.435px 343.935px",
          }}
        />
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__elq0mr5xf8ltc"
          d="M56.27 313.16s-1.1 24.72 11.59 41.08 34.36 18.18 34.36 18.18 1.09-24.72-11.6-41.08-34.35-18.18-34.35-18.18z"
          style={{
            transformOrigin: "79.2447px 342.79px",
          }}
        />
        <g
          className="prefix__animable"
          style={{
            transformOrigin: "113.373px 300.865px",
          }}
          opacity={0.5}
          id="prefix__eluig22gatr9q"
        >
          <path
            className="prefix__animable"
            id="prefix__elo6gr53sbvw7"
            d="M112.71 229.31s26.3 30.4 26.44 68.06-25.92 68.31-25.92 68.31-26.3-30.4-26.44-68.05 25.92-68.32 25.92-68.32z"
            style={{
              transformOrigin: "112.97px 297.495px",
            }}
            fill="#fff"
          />
          <path
            className="prefix__animable"
            id="prefix__el6710hb4uzfx"
            d="M170.5 315.45s.16 24.75-13.13 40.48-35 16.49-35 16.49-.17-24.75 13.13-40.48 35-16.49 35-16.49z"
            style={{
              transformOrigin: "146.435px 343.935px",
            }}
            fill="#fff"
          />
          <path
            className="prefix__animable"
            id="prefix__el198yxlmq1ud"
            d="M56.27 313.16s-1.1 24.72 11.59 41.08 34.36 18.18 34.36 18.18 1.09-24.72-11.6-41.08-34.35-18.18-34.35-18.18z"
            style={{
              transformOrigin: "79.2447px 342.79px",
            }}
            fill="#fff"
          />
        </g>
        <path
          className="prefix__animable"
          id="prefix__elsaqo3wjml3"
          d="M96.57 330.89a.62.62 0 011-.13l13.82 12.35a.69.69 0 001.11-.62l-.15-28.49a.86.86 0 00-.23-.58l-20.75-20.91a.85.85 0 01-.06-1.1.63.63 0 011-.07l18.93 19.08a.67.67 0 001.13-.58l-.12-31.35a.82.82 0 00-.23-.58l-13.77-13.54a.85.85 0 01-.07-1.1.63.63 0 011-.08L111.07 275c.44.44 1.13.08 1.13-.59l-.2-44.32a.74.74 0 01.68-.78.73.73 0 01.69.77l.25 65.62a.68.68 0 001.25.44l15.4-25.67a.64.64 0 01.95-.2.85.85 0 01.18 1.08l-17.65 29.41-.11-.09.1 26.89a.68.68 0 001.14.58l18.78-19.24a.62.62 0 011 .06.85.85 0 01-.06 1.1l-20.81 21.33.13 33.52a.73.73 0 01-.68.78.72.72 0 01-.69-.77l-.07-18.44a.82.82 0 00-.26-.61L96.69 332a.85.85 0 01-.12-1.11z"
          style={{
            transformOrigin: "112.985px 297.5px",
          }}
          fill="#fff"
        />
        <path
          className="prefix__animable"
          id="prefix__elo8q8ktdha6"
          d="M57 314.79a.63.63 0 011 .07l20.86 26.54c.44.57 1.27.15 1.18-.61l-1.71-15.48a.75.75 0 01.59-.87.7.7 0 01.76.67l2.24 20.17L90 355.65a.67.67 0 001.19-.43l1.47-14.8a.72.72 0 01.76-.69.75.75 0 01.6.86l-1.75 17.56a.85.85 0 00.17.61l10.89 13.87a.86.86 0 01-.05 1.1.63.63 0 01-1-.06l-5.88-7.49a.65.65 0 00-.55-.26l-10.85.75a.73.73 0 01-.73-.73.76.76 0 01.65-.83l8.69-.6a.8.8 0 00.47-1.29l-8.71-11.09a.65.65 0 00-.51-.25H69.34a.74.74 0 01-.68-.78.73.73 0 01.68-.78h13.08A.8.8 0 0083 349l-9.6-12.23a.65.65 0 00-.52-.26l-10 .14a.73.73 0 01-.69-.77.73.73 0 01.67-.79l7.6-.1a.8.8 0 00.5-1.3L57 315.89a.86.86 0 010-1.1z"
          style={{
            transformOrigin: "80.1531px 344.262px",
          }}
          fill="#fff"
        />
        <path
          className="prefix__animable"
          id="prefix__eltwijpmm82z"
          d="M127.77 350.28a.72.72 0 01.77.67l1.17 9.83a.67.67 0 001.17.44l9.05-10.71a.84.84 0 00.18-.6L139 332.27a.74.74 0 01.63-.83.71.71 0 01.73.71l1 14.87a.68.68 0 001.18.48l10-11.83a.91.91 0 00.19-.61l-.87-11.32a.77.77 0 01.62-.85.72.72 0 01.74.71l.66 8.62a.67.67 0 001.17.47l14.55-17.22a.63.63 0 011 0 .86.86 0 010 1.1l-21.68 25.67c-.47.55 0 1.45.62 1.3l13.43-3.11a.69.69 0 01.8.61.78.78 0 01-.53.92L145.67 346l-8.47 10a.8.8 0 00.47 1.32l13.06.57a.73.73 0 01.66.8.71.71 0 01-.71.74l-15.5-.67a.64.64 0 00-.52.24l-11.32 13.4a.63.63 0 01-1 0 .86.86 0 010-1.1l6.12-7.24a.85.85 0 00.18-.64l-1.46-12.28a.77.77 0 01.59-.86z"
          style={{
            transformOrigin: "146.47px 343.935px",
          }}
          fill="#fff"
        />
      </g>
      <g
        className="prefix__animable"
        id="prefix__Checklist--inject-1"
        style={{
          transformOrigin: "243.756px 242.077px",
        }}
      >
        <path
          className="prefix__animable"
          id="prefix__ellc465wdg2gq"
          d="M377.2 329.43l-148.55 41.81a7.77 7.77 0 01-8.49-3.5L107.51 165a4.41 4.41 0 012.83-6.68l148.55-41.81a7.75 7.75 0 018.48 3.49L380 322.74a4.42 4.42 0 01-2.8 6.69z"
          style={{
            transformOrigin: "243.756px 243.874px",
          }}
          fill="#263238"
          stroke="#000"
          strokeMiterlimit={10}
        />
        <path
          className="prefix__animable"
          id="prefix__elbn4egg2qvca"
          d="M226.56 362.35s85.84-20.42 143.62-40.43z"
          style={{
            transformOrigin: "298.37px 342.135px",
          }}
          fill="#884849"
        />
        <path
          className="prefix__animable"
          id="prefix__elyl7z7oyuwlg"
          style={{
            transformOrigin: "242.825px 242.18px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M370.18 321.92l-143.62 40.43-111.09-199.92 143.62-40.42 111.09 199.91z"
        />
        <path
          className="prefix__animable"
          id="prefix__elf7tujamwxd6"
          style={{
            transformOrigin: "222.125px 162.78px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M216.55 164.35l11.15-3.14"
        />
        <path
          className="prefix__animable"
          id="prefix__elscxold2v1eo"
          style={{
            transformOrigin: "173.565px 176.45px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M139.21 186.12l68.71-19.34"
        />
        <path
          className="prefix__animable"
          id="prefix__elu8rxs8kfgrd"
          style={{
            transformOrigin: "218.17px 172.87px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M204.32 176.77l27.7-7.8"
        />
        <path
          className="prefix__animable"
          id="prefix__elmcl66tnj23c"
          style={{
            transformOrigin: "179.315px 183.81px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M161.87 188.72l34.89-9.82"
        />
        <path
          className="prefix__animable"
          id="prefix__elp13kr843t1p"
          style={{
            transformOrigin: "149.455px 192.21px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M143.52 193.88l11.87-3.34"
        />
        <path
          className="prefix__animable"
          id="prefix__eldgtmd86hgv7"
          style={{
            transformOrigin: "209.355px 184.335px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M182.37 191.93l53.97-15.19"
        />
        <path
          className="prefix__animable"
          id="prefix__eloxio03q836"
          style={{
            transformOrigin: "159.17px 198.46px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M147.84 201.65l22.66-6.38"
        />
        <path
          className="prefix__animable"
          id="prefix__elb2c9shim1d8"
          d="M266.56 158.5a12.85 12.85 0 00-14-5.78c-5.17 1.46-7.26 6.4-4.68 11.05a12.82 12.82 0 0014 5.78c5.12-1.45 7.26-6.4 4.68-11.05z"
          style={{
            transformOrigin: "257.216px 161.137px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
        />
        <path
          className="prefix__animable"
          id="prefix__ele37cx0821nb"
          style={{
            transformOrigin: "164.025px 219.17px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M169.6 217.6l-11.15 3.14"
        />
        <path
          className="prefix__animable"
          id="prefix__el1icg2aoq3h9"
          style={{
            transformOrigin: "212.585px 205.5px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M246.94 195.83l-68.71 19.34"
        />
        <path
          className="prefix__animable"
          id="prefix__elcs6ki3km2z8"
          style={{
            transformOrigin: "176.61px 224.61px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M190.46 220.71l-27.7 7.8"
        />
        <path
          className="prefix__animable"
          id="prefix__elr4hph8r5ici"
          style={{
            transformOrigin: "215.465px 213.67px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M232.91 208.76l-34.89 9.82"
        />
        <path
          className="prefix__animable"
          id="prefix__el4yumrxzpxpk"
          style={{
            transformOrigin: "245.325px 205.27px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M251.26 203.6l-11.87 3.34"
        />
        <path
          className="prefix__animable"
          id="prefix__elzl05pd2h8jj"
          style={{
            transformOrigin: "194.06px 228.675px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M221.04 221.08l-53.96 15.19"
        />
        <path
          className="prefix__animable"
          id="prefix__el5j4igmf6bz9"
          style={{
            transformOrigin: "244.245px 214.55px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M255.58 211.36l-22.67 6.38"
        />
        <path
          className="prefix__animable"
          id="prefix__el5gvyldqi9vq"
          d="M285.8 193.13a12.82 12.82 0 00-14-5.78c-5.17 1.45-7.26 6.4-4.68 11a12.81 12.81 0 0014 5.78c5.16-1.41 7.26-6.36 4.68-11z"
          style={{
            transformOrigin: "276.459px 195.741px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
        />
        <path
          className="prefix__animable"
          id="prefix__el91zvkikxxee"
          style={{
            transformOrigin: "260.605px 232.02px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M255.03 233.59l11.15-3.14"
        />
        <path
          className="prefix__animable"
          id="prefix__elwqlkwiwu8no"
          style={{
            transformOrigin: "212.045px 245.69px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M177.69 255.36l68.71-19.34"
        />
        <path
          className="prefix__animable"
          id="prefix__el5r26d6uuaq9"
          style={{
            transformOrigin: "256.65px 242.12px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M242.8 246.02l27.7-7.8"
        />
        <path
          className="prefix__animable"
          id="prefix__elolo2w6lw3jg"
          style={{
            transformOrigin: "217.795px 253.055px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M200.35 257.97l34.89-9.83"
        />
        <path
          className="prefix__animable"
          id="prefix__elutyfxexo7vo"
          style={{
            transformOrigin: "187.935px 261.46px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M182 263.13l11.87-3.34"
        />
        <path
          className="prefix__animable"
          id="prefix__elj8bndxnbq7"
          style={{
            transformOrigin: "247.83px 253.58px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M220.85 261.17l53.96-15.18"
        />
        <path
          className="prefix__animable"
          id="prefix__eld52hws5v6h"
          style={{
            transformOrigin: "197.65px 267.71px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M186.32 270.9l22.66-6.38"
        />
        <ellipse
          className="prefix__animable"
          cx={295.68}
          cy={230.38}
          rx={8.48}
          ry={10.73}
          style={{
            transformOrigin: "295.68px 230.38px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          transform="rotate(-68.54)"
          id="prefix__elm0yvk8h236o"
        />
        <path
          className="prefix__animable"
          id="prefix__el8w5gknuq8p4"
          style={{
            transformOrigin: "202.505px 288.42px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M208.08 286.85l-11.15 3.14"
        />
        <path
          className="prefix__animable"
          id="prefix__elbe8b19xklo8"
          style={{
            transformOrigin: "251.065px 274.75px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M285.42 265.08l-68.71 19.34"
        />
        <path
          className="prefix__animable"
          id="prefix__elalcfnht9326"
          style={{
            transformOrigin: "215.09px 293.855px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M228.94 289.96l-27.7 7.79"
        />
        <path
          className="prefix__animable"
          id="prefix__el6lhidqsw74h"
          style={{
            transformOrigin: "253.945px 282.92px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M271.39 278.01l-34.89 9.82"
        />
        <path
          className="prefix__animable"
          id="prefix__ele3f9ptv2nm6"
          style={{
            transformOrigin: "283.805px 274.51px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M289.74 272.84l-11.87 3.34"
        />
        <path
          className="prefix__animable"
          id="prefix__eljgxfx2284h"
          style={{
            transformOrigin: "232.54px 297.925px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M259.52 290.33l-53.96 15.19"
        />
        <path
          className="prefix__animable"
          id="prefix__eldkeltslu1lv"
          style={{
            transformOrigin: "282.72px 283.8px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M294.05 280.61l-22.66 6.38"
        />
        <path
          className="prefix__animable"
          id="prefix__elnr9xmszkcch"
          d="M324.27 262.37a12.83 12.83 0 00-14-5.78c-5.17 1.46-7.26 6.4-4.68 11.05a12.82 12.82 0 0014 5.78c5.17-1.42 7.27-6.42 4.68-11.05z"
          style={{
            transformOrigin: "314.932px 265.006px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
        />
        <path
          className="prefix__animable"
          id="prefix__eln5uc4weg2l"
          style={{
            transformOrigin: "299.085px 301.27px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M293.51 302.84l11.15-3.14"
        />
        <path
          className="prefix__animable"
          id="prefix__el5snwn0qo6mw"
          style={{
            transformOrigin: "250.525px 314.94px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M216.17 324.61l68.71-19.34"
        />
        <path
          className="prefix__animable"
          id="prefix__el9izhb9isauc"
          style={{
            transformOrigin: "295.13px 311.365px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M281.28 315.26l27.7-7.79"
        />
        <path
          className="prefix__animable"
          id="prefix__eljmtcy579n0r"
          style={{
            transformOrigin: "256.275px 322.3px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M238.83 327.21l34.89-9.82"
        />
        <path
          className="prefix__animable"
          id="prefix__el2mjerf32nhk"
          style={{
            transformOrigin: "226.415px 330.705px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M220.48 332.38l11.87-3.35"
        />
        <path
          className="prefix__animable"
          id="prefix__eln2koq02xgv"
          style={{
            transformOrigin: "286.31px 322.825px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M259.33 330.42l53.96-15.19"
        />
        <path
          className="prefix__animable"
          id="prefix__elx5uey74b2z"
          style={{
            transformOrigin: "236.13px 336.95px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          d="M224.8 340.14l22.66-6.38"
        />
        <ellipse
          className="prefix__animable"
          cx={334.16}
          cy={299.63}
          rx={8.48}
          ry={10.73}
          style={{
            transformOrigin: "334.16px 299.63px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          transform="rotate(-68.54)"
          id="prefix__elagjj09b6rqn"
        />
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__el8kpnkro1657"
          d="M210.47 125.4l-18.88 5.31c-1.43.06-3-.05-3.44-.78-1-1.88.28-3.64.68-5.3A7.6 7.6 0 00188 119a13.17 13.17 0 00-14.39-5.93c-5.3 1.5-7.44 6.57-4.8 11.33a12.41 12.41 0 005.56 5s3.83 1.71 4.6 3.09c.41.73-.66 1.59-1.75 2.24l-20.35 5.73a4.86 4.86 0 00-3.11 7.35l2.75 4.94 66.07-18.59-2.75-4.95a8.53 8.53 0 00-9.36-3.81zm-33.63-6.46a4.21 4.21 0 014.61 1.9 2.39 2.39 0 01-1.54 3.62 4.2 4.2 0 01-4.6-1.89 2.4 2.4 0 011.53-3.63z"
          style={{
            transformOrigin: "187.795px 132.719px",
          }}
          stroke="#263238"
          strokeMiterlimit={10}
        />
      </g>
      <g
        className="prefix__animable"
        id="prefix__Character--inject-1"
        style={{
          transformOrigin: "366.676px 261.661px",
        }}
      >
        <path
          className="prefix__animable"
          id="prefix__elu4v94cqh6tp"
          d="M403.82 188.51s2.17-4.62.81-8.16-26.39-9.25-26.39-9.25-5.71 0-5.44 3.27 20.95 15.78 22.58 16.86 7.89 3.54 8.44-2.72z"
          style={{
            transformOrigin: "388.934px 181.862px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el7q8f1dba6ke"
          d="M380.31 166.53a49.21 49.21 0 01-4.53-1.94c-2.16-1.08.21 3.89 1.08 4.1s3 .65 3 .65z"
          style={{
            transformOrigin: "377.603px 166.888px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__elhsp31qkvl0t"
          d="M305.61 214.9l-12.51 15.23 1.08 2.18 17.69-4.31 124.51-72.48a10.79 10.79 0 003.72-15h0a10.78 10.78 0 00-15-3.33z"
          style={{
            transformOrigin: "367.41px 183.884px",
          }}
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elyvkafid6te"
          style={{
            transformOrigin: "366.14px 187.98px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M368.17 186.67l-4.06 2.62"
        />
        <path
          className="prefix__animable"
          id="prefix__elu3d61v0vnud"
          style={{
            transformOrigin: "388.125px 173.84px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M403.12 164.19l-29.99 19.3"
        />
        <path
          className="prefix__animable prefix__svg-color"
          id="prefix__eltz1yvnkckin"
          d="M294.26 229.81s-3.11 1.3-2.63 2.61 4.94-1.86 4.94-1.86z"
          style={{
            transformOrigin: "294.075px 231.274px",
          }}
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__eln3loaz9q23"
          d="M293.1 230.13l1.08 2.18 17.69-4.31c-9.8-3.81-6.26-13.06-6.26-13.06z"
          style={{
            transformOrigin: "302.485px 223.625px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elwc0rlkd0vsg"
          d="M440.1 140.45a10.78 10.78 0 00-15-3.33l-21.72 14.14a12.73 12.73 0 00-.88 4.68 12.15 12.15 0 0011.85 12.38l22.07-12.84a10.79 10.79 0 003.68-15.03z"
          style={{
            transformOrigin: "422.121px 151.854px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elzvdpzwt4gmd"
          d="M440.1 140.45a10.78 10.78 0 00-15-3.33l-16.5 10.75a12.08 12.08 0 00-1.21 5.28 11.84 11.84 0 0011.7 12h.81l16.52-9.61a10.79 10.79 0 003.68-15.09z"
          style={{
            transformOrigin: "424.581px 150.269px",
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elwwaybk4dni"
          d="M366.5 171.5s-2.42 0-2.42 1.1-.64 5 .76 4.89 1.28-2.56 1.4-3.42.26-2.57.26-2.57z"
          style={{
            transformOrigin: "365.217px 174.496px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elq3mhm2m5m7q"
          d="M369.14 167.52s-2.16-.67-2.66.66-1 7.65.5 7.48 1.83-5.81 2.16-8.14z"
          style={{
            transformOrigin: "367.56px 171.507px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el5oaz4r9cerv"
          d="M372.47 167.85s-2.5-2.16-3.33-.33-1 7 0 7 2.5-2.16 2.83-3.32a23 23 0 00.5-3.35z"
          style={{
            transformOrigin: "370.461px 170.652px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elmfixleqw8l"
          d="M376 168.85s-3.16-2.66-3.66-2.16-2.5 5.62-1 6.65 3.4-3.57 3.4-3.57z"
          style={{
            transformOrigin: "373.396px 170.059px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el4elbjteb2y"
          d="M330.33 184.63s.35 5.33 1.77 6.58a15.57 15.57 0 012.31 2.49 36.69 36.69 0 01.36 4.09c0 1.6 1.07 1.42 2 1.42s2.49 3 3.38 4.44a24.74 24.74 0 006 5.34c1.6.89 4.27-2.49 4.27-2.49l1.24 3.56 12.63-8.18-6.22-12.1a29 29 0 00-3.74-11.91c-3.56-6.4-8.36-10-16.36-7.65s-7.64 14.41-7.64 14.41z"
          style={{
            transformOrigin: "347.309px 189.779px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el7qhz0kvivkq"
          d="M344.94 160.93a29 29 0 00-8.73 1.75c-4.67 1.65-5.82 8.15-9.89 10.48s-2.33-2-4.37-.88-4.07 11.35.29 14.26 10.77-.87 10.77-.87-.58 2 1.16 2.62a29.94 29.94 0 003.21.87s-.3 3.5 2.32 3.2.3-4.36.3-4.36 4.95 9.6 8.74 10.77 9.31-4.08 10.76-6.41.29-18.91-2.91-25.31-11.65-6.12-11.65-6.12z"
          style={{
            transformOrigin: "339.885px 179.933px",
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elulqp1tvkzv9"
          d="M340.11 194.23s-1.78-6.76 1.06-5.87 4.45 6.94 4.27 7.65-1.6 1.06-2.31.89"
          style={{
            transformOrigin: "342.506px 192.611px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__eln24paakind"
          d="M360.66 196.68a75 75 0 00-9.48 6.32c-3 2.67-5.69 10.67-5.69 10.67s18.32-12.45 21.52-14.22 3.91-2.67 3.91-2.67-6.7-.99-10.26-.1z"
          style={{
            transformOrigin: "358.205px 204.989px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elszy1b05j91n"
          d="M385.32 185.79a11.23 11.23 0 017.34-5.44c5.17-1.09 12 2.45 12.79 8.16s-3.54 8.44-6.26 8.44-5.17-3.81-5.17-3.81-8.7-3.27-8.7-7.35z"
          style={{
            transformOrigin: "395.432px 188.552px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el1zkb32mgo5u"
          d="M312.41 246.46l8.71-23.13a5.34 5.34 0 01-3.54 1.36c-2.18 0-7.34-.81-6-1.63s6.52-4.35 6.52-4.35-3.8.82-4.89 0-1.63-1.91-.82-2.18 2.45 0 5.44-1.09 4.35-2.17 6.8-2.17 4.63 4.35 4.63 5.71-1.91 27.2-1.91 28.29 0 2.18-7.07 3-8.14-.82-7.87-3.81z"
          style={{
            transformOrigin: "320.31px 231.875px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__else88tuem5ri"
          style={{
            transformOrigin: "321.765px 220.79px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M321.12 223.33l1.29-5.08"
        />
        <path
          className="prefix__animable"
          id="prefix__elkjxsgfh8lb"
          d="M320.84 240.74s-8.7 2.45-10.33 6.26-1.36 9.52 1.9 12.79 4.63 4.08 8.16 2.72 4.36-3.54 4.63-5.72 2.17-9.52-4.36-16.05z"
          style={{
            transformOrigin: "317.599px 251.94px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el3e9szcplg33"
          d="M317.31 356.09s-7.62-3.54-10.61-2.18-3 2.72.81 8.71 14.42 13.33 18 11.69.27-12.51.27-12.51z"
          style={{
            transformOrigin: "315.86px 364.071px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elhez522tyvqh"
          d="M311.87 364.52a42.35 42.35 0 01-6.6-9.75c-1.4 1.24-.8 3.06 2.24 7.85 3.81 6 14.42 13.33 18 11.69a3.24 3.24 0 001.49-2.71c-5 2.11-10.17-1.6-15.13-7.08z"
          style={{
            transformOrigin: "315.776px 364.657px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__els9pnjwovyl"
          d="M356.48 272.84l.27 8.17s-16.59 6.25-24.75 12-13.6 10.06-14.42 15.5-.27 47.61-.27 47.61-2.45.54-1.63 2.17 2.44 7.62 4.62 8.16 4.9 1.64 5.44.28 2.18-9.52 3.27-12.24S332 339 333.63 330a94.41 94.41 0 001.63-15.23 87.92 87.92 0 0016.87-4.08c9.52-3.27 23.39-9.25 23.39-9.25s11.16 28 13.61 33.46 12 17.68 13.87 21.22 13.87 18.77 13.87 18.77 4.63-4.08 7.62-4.08h4.08s-4.9-13.6-9-21.77-12.51-21.49-12.51-21.49-7.61-47.59-7.89-50.87c-.53-6.28-4.55-15.76-4.55-15.76s-13.11-2.77-38.14 11.92z"
          style={{
            transformOrigin: "372.041px 317.801px",
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elt8yatu305qb"
          d="M416.87 374.86a35.77 35.77 0 00-1.9 3.53c-.27.82-6.26 1.64-5.44 4.36s7.07 4.89 10.06 5.17 9-3.81 10.07-4.9c.64-.64 1.6-4.87 1.09-8.16a8.43 8.43 0 00-2.18-4.08s-5.17-7.89-11.7 4.08z"
          style={{
            transformOrigin: "420.173px 378.081px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elxtn79ai0o1b"
          d="M430.75 374.86a8 8 0 00-1.85-3.72 8.31 8.31 0 00-6 2.63c-2.72 3.26-1.36 10.61-4.63 11.7-2.43.81-6.68-2.16-8.68-3.72a1.54 1.54 0 000 1c.81 2.72 7.07 4.89 10.06 5.17s9-3.81 10.07-4.9c.58-.64 1.54-4.87 1.03-8.16z"
          style={{
            transformOrigin: "420.2px 379.537px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__el7bjx1vqu5z2"
          d="M423.67 376.76c-1.08 4.9.28 8.16-4.08 11.16 3 .27 9-3.81 10.07-4.9.64-.64 1.6-4.87 1.09-8.16a6.42 6.42 0 00-.67-1.95h-.42s-4.9-1.04-5.99 3.85z"
          style={{
            transformOrigin: "425.242px 380.375px",
          }}
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elxukduqe5uhc"
          style={{
            transformOrigin: "386.265px 296.92px",
          }}
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M378.24 300.86l16.05-7.88"
        />
        <path
          className="prefix__animable"
          id="prefix__elvpdsf6b7u5h"
          style={{
            transformOrigin: "392.525px 296.375px",
          }}
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M385.59 297.33l13.87-1.91"
        />
        <path
          className="prefix__animable"
          id="prefix__ela5woq7i01ip"
          d="M391 183.89c-4.62.54-16.59 8.7-17.41 9.52s-4.08 2.72-5.44 3.81c0 0-28 17.41-30.47 19s-7.34 4.9-7.07 6-14.42 25-14.42 25-4.35 5.72-.27 10.88 7.89 1.64 9.79.55 16.87-15.24 16.87-15.24l13.8 33s19.11-12.81 39.52-12l-13.61-53.32s18.78-14.14 19.32-19-5.96-8.75-10.61-8.2z"
          style={{
            transformOrigin: "357.908px 230.124px",
          }}
          fill="#fff"
        />
        <g
          className="prefix__animable"
          id="prefix__elrvnmk5q9uvd"
          style={{
            transformOrigin: "358.086px 230.164px",
          }}
          clipPath="url(#prefix__clip-path--inject-1)"
        >
          <path
            className="prefix__animable prefix__svg-colo"
            id="prefix__el1ggueasnv15"
            d="M391 183.89c-1.85.22-4.88 1.66-7.91 3.36a3.58 3.58 0 013.65 1.52c2.25 2.89-2.57 12.2-2.57 12.2s-2-12.86-1.92-9-1 15.73-1 15.73-6.42-7.7-6.1-5.78 4.49 19.9 7.06 26.64-1.93 25.67-4.49 31.12-1-23.1-3.86-16-12.51 26.63-14.76 28.56.72-26.17 1.52-29.83c-1 3.06-7.61 22.45-7.61 22.45l-9-23.11s-15.4-12.51-13.8-10.27 5.46 9.31 5.46 9.31-9.31-1.93-7.7-1.28 8.66 4.17 8.66 4.17-14.44 10.27-18.61 11.55a16.74 16.74 0 01-3.44.63 8.18 8.18 0 001.29 2.32c4.08 5.17 7.89 1.64 9.79.55s16.87-15.24 16.87-15.24l13.8 33s19.11-12.81 39.52-12l-13.61-53.32s18.78-14.14 19.32-19-5.91-8.83-10.56-8.28z"
            style={{
              transformOrigin: "358.086px 230.164px",
            }}
          />
          <path
            className="prefix__animable"
            d="M391 183.89c-1.85.22-4.88 1.66-7.91 3.36a3.58 3.58 0 013.65 1.52c2.25 2.89-2.57 12.2-2.57 12.2s-2-12.86-1.92-9-1 15.73-1 15.73-6.42-7.7-6.1-5.78 4.49 19.9 7.06 26.64-1.93 25.67-4.49 31.12-1-23.1-3.86-16-12.51 26.63-14.76 28.56.72-26.17 1.52-29.83c-1 3.06-7.61 22.45-7.61 22.45l-9-23.11s-15.4-12.51-13.8-10.27 5.46 9.31 5.46 9.31-9.31-1.93-7.7-1.28 8.66 4.17 8.66 4.17-14.44 10.27-18.61 11.55a16.74 16.74 0 01-3.44.63 8.18 8.18 0 001.29 2.32c4.08 5.17 7.89 1.64 9.79.55s16.87-15.24 16.87-15.24l13.8 33s19.11-12.81 39.52-12l-13.61-53.32s18.78-14.14 19.32-19-5.91-8.83-10.56-8.28z"
            style={{
              transformOrigin: "358.086px 230.164px",
            }}
            fill="#fff"
            opacity={0.5}
            id="prefix__el1wlltqk38dj"
          />
        </g>
        <path
          className="prefix__animable"
          id="prefix__elsftbp6ul4f"
          d="M391 183.89c-4.62.54-16.59 8.7-17.41 9.52s-4.08 2.72-5.44 3.81c0 0-28 17.41-30.47 19s-7.34 4.9-7.07 6-14.42 25-14.42 25-4.35 5.72-.27 10.88 7.89 1.64 9.79.55 16.87-15.24 16.87-15.24l13.8 33s19.11-12.81 39.52-12l-13.61-53.32s18.78-14.14 19.32-19-5.96-8.75-10.61-8.2z"
          style={{
            transformOrigin: "357.908px 230.124px",
          }}
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elbr5d8t9p9wq"
          d="M356.41 276.41a71.61 71.61 0 011.15-22.41"
          style={{
            transformOrigin: "356.784px 265.205px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elym3qyb0eynk"
          d="M353.83 259.74s0-4.6 5.17-19"
          style={{
            transformOrigin: "356.415px 250.24px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__animable"
          id="prefix__elpk89qlhgxqr"
          style={{
            transformOrigin: "337.17px 237.48px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M332 231.5l10.61 11.96-10.88-7.89"
        />
        <path
          className="prefix__animable"
          id="prefix__el05v482ot5fyp"
          style={{
            transformOrigin: "384.09px 201.3px",
          }}
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M382.32 195.86v15.23l3.54-19.58"
        />
        <path
          className="prefix__animable"
          id="prefix__el32sxagcmwwx"
          d="M360.78 242a2 2 0 00-.09.34c.12-.34.16-.47.09-.34z"
          style={{
            transformOrigin: "360.752px 242.145px",
          }}
          fill="#263238"
        />
      </g>
      <defs>
        <filter id="prefix__active" height="200%">
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="prefix__hover" height="200%">
          <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent;