import React, {Fragment} from 'react';

// Constants
import {MONTH} from '../../core/constants';

// Components
import WithdrawHistoryIcon from './WithdrawHistoryIcon';

// Functions
import {formatPhone} from '../../core/common-functions';

// Images
import coin from '../../images/svg/coin.svg';

const WithdrawHistoryItem = ({index, value, date, lang, charity}) => {
	return (
		<Fragment key={index}>
			<h4>{`${date.getDate()} ${MONTH[date.getMonth()][lang]}`}</h4>
			{value.map((item) => {
				return (
					<div className="modal-data-list--item" key={item.ID}>
						<div>
							<WithdrawHistoryIcon status={item.STATUS} />
							{item.PHONE ? formatPhone(item.PHONE) : charity}
						</div>
						<div>
							{item.AMOUNT}
							<img src={coin} alt="Лемур" width="16" />
						</div>
					</div>
				);
			})}
		</Fragment>
	);
};

export default WithdrawHistoryItem;
