import React from 'react';

// Components
import Spinner from '../Spinner';

const AvailablePollsLoading = () => {
    return (
        <div className="vertical-centered">
            <Spinner />
        </div>
    );
}

export default AvailablePollsLoading;
