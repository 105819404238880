import React from 'react';
import {useHistory} from 'react-router-dom';

// Components
import AvailablePollsLoading from './AvailablePollsLoading';
import AvailablePollsItem from './AvailablePollsItem';
import AvailablePollsEmpty from './AvailablePollsEmpty';

const AvailablePolls = ({polls, loadAvailablePolls, texts, lang}) => {
	const history = useHistory();

	if (loadAvailablePolls) {
		return <AvailablePollsLoading />;
	} else {
		if (polls.length > 0) {
			return (
				<table>
					<tbody>
						{polls.map((poll) => {
							return (
								<AvailablePollsItem
									history={history}
									poll={poll}
									texts={texts}
									key={poll.poll_id}
									lang={lang}
								/>
							);
						})}
					</tbody>
				</table>
			);
		} else {
			return <AvailablePollsEmpty texts={texts} />;
		}
	}
};

export default AvailablePolls;
