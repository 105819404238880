import React from 'react';
import {MainServiceConsumer} from '../MainServiceContext';

const WithMainService = () => (Wrapped) => {
    return props => {
        return(
            <MainServiceConsumer>
                {
                    mainService => {
                        return <Wrapped {...props} mainService={mainService} />;
                    }
                }
            </MainServiceConsumer>
        );
    }
}

export default WithMainService;
