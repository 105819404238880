import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';

// Components
import Spinner from '../Spinner';
import WithdrawHistory from '../WithdrawHistory';

const WithdrawHistoryDialog = ({
	loading,
	open,
	setOpen,
	data,
	titleText,
	closeText,
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClose = () => {
		setOpen(false);
	};

	const Content = () => {
		if (loading) {
			return (
				<div className="modal-data-spinner">
					<Spinner />
				</div>
			);
		} else {
			return <WithdrawHistory data={data} />;
		}
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			className="custom-lemur-dialog">
			<DialogTitle id="responsive-dialog-title">{titleText}</DialogTitle>
			<DialogContent>
				<Content />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" autoFocus>
					{closeText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default WithdrawHistoryDialog;
