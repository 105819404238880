import React, {Fragment} from 'react';

// Images
import coin from '../../images/svg/coin.svg';

// Constants
import {MONTH} from '../../core/constants';

const EnrollmentHistoryItem = ({index, value, date, lang}) => {
	return (
		<Fragment key={index}>
			<h4>{`${date.getDate()} ${MONTH[date.getMonth()][lang]}`}</h4>
			{value.map((item, index) => {
				const {poll_name: name, CREDS: count} = item;
				return (
					<div className="modal-data-list--item" key={index}>
						<div>
							{name
								? name[lang]
									? name[lang]
									: name[Object.keys(name)[0]]
								: 'Архивный опрос'}
						</div>
						<div>
							+{count}
							<img src={coin} alt="Лемур" width="16" />
						</div>
					</div>
				);
			})}
		</Fragment>
	);
};

export default EnrollmentHistoryItem;
