import React from 'react';

// Images
import logo from '../../images/svg/logo-vertical.svg';

// Component
import Spinner from '../Spinner';

const AppLoader = () => {
	return (
		<div className="app-loader">
			<div className="app-loader__inner">
				<img src={logo} alt="Lemur" />
				<Spinner />
			</div>
		</div>
	);
};

export default AppLoader;
