import React from 'react';
import {Link} from 'react-router-dom';

// Images
import Image from '../../images/svg/intro-1';

const IntroBlock = ({text, isLogin}) => {
	return (
		<div className="intro-block flex align-center mb-8em">
			<div className="text">
				<h2>{text.start_page_block_1_h2}</h2>
				<h3>{text.start_page_block_1_h3}</h3>
				<p>{text.start_page_block_1_list_head}</p>
				<ol>
					{text.start_page_block_1_list.map((item, index) => {
						return <li key={index}>{item}</li>;
					})}
				</ol>
				<div className="mt-4em">
					{!isLogin && (
						<Link
							className="btn btn-colored btn-color-shadow"
							to="/sign-up">
							{text.start_page_block_1_button}
						</Link>
					)}
				</div>
			</div>
			<div className="icon">
				<Image />
			</div>
		</div>
	);
};

export default IntroBlock;
