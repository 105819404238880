import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import MainService from './services/MainService';
import {MainServiceProvider} from './components/MainServiceContext';

import './App.sass';

import store from './store';

const mainService = new MainService();

ReactDOM.render(
	<Provider store={store}>
		<ErrorBoundary>
			<MainServiceProvider value={mainService}>
				<Router>
					<App />
				</Router>
			</MainServiceProvider>
		</ErrorBoundary>
	</Provider>,
	document.getElementById('root'),
);
